import axios from 'axios';
import extenso from 'extenso';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React from 'react';
import InputMask from 'react-input-mask';
import 'react-select/dist/react-select.css';
import { toast } from 'react-toastify';
import {
  Button,
  InputGroup,
  InputGroupAddon,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {
  getBetaImage,
  getLogoAlpha,
  getPropostaImage,
  getUltrapress,
} from '../actions/dataUrlImages';
import { REQUEST_URL, URL_CONTRATO_API } from '../config/urls';
import TelegramLog from '../services/telegram';

export default class Contrato extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orcamento: { observacao: 'aoihdbfoahsbdfoahsbdfo' },
      estadosJson: [
        { nome: 'Acre', sigla: 'AC' },
        { nome: 'Alagoas', sigla: 'AL' },
        { nome: 'Amapá', sigla: 'AP' },
        { nome: 'Amazonas', sigla: 'AM' },
        { nome: 'Bahia', sigla: 'BA' },
        { nome: 'Ceará', sigla: 'CE' },
        { nome: 'Distrito Federal', sigla: 'DF' },
        { nome: 'Espírito Santo', sigla: 'ES' },
        { nome: 'Goiás', sigla: 'GO' },
        { nome: 'Maranhão', sigla: 'MA' },
        { nome: 'Mato Grosso', sigla: 'MT' },
        { nome: 'Mato Grosso do Sul', sigla: 'MS' },
        { nome: 'Minas Gerais', sigla: 'MG' },
        { nome: 'Pará', sigla: 'PA' },
        { nome: 'Paraíba', sigla: 'PB' },
        { nome: 'Paraná', sigla: 'PR' },
        { nome: 'Pernambuco', sigla: 'PE' },
        { nome: 'Piauí', sigla: 'PI' },
        { nome: 'Rio de Janeiro', sigla: 'RJ' },
        { nome: 'Rio Grande do Norte', sigla: 'RN' },
        { nome: 'Rio Grande do Sul', sigla: 'RS' },
        { nome: 'Rondônia', sigla: 'RO' },
        { nome: 'Roraima', sigla: 'RR' },
        { nome: 'Santa Catarina', sigla: 'SC' },
        { nome: 'São Paulo', sigla: 'SP' },
        { nome: 'Sergipe', sigla: 'SE' },
        { nome: 'Tocantins', sigla: 'TO' },
      ],
      contratantes: [
        {
          id: '',
          nome: '',
          cpf: '',
          rg: '',
          cnpj: '',
          endereco: '',
          telCel: '',
          telRes: '',
          bairro: '',
          numero: 0,
          email: '',
          cidade: '',
          estado: '',
          CEP: '',
        },
      ],
      contratada: {
        representante: '',
        carteira: '',
        telefone: '',
        estado: '',
        cidade: '',
        rua: '',
        estadoDaCarteira: '',
      },
      produtosEspecificados: '',
      turma: {
        nome: '',
        turno: '',
        curso: '',
        faculdade: '',
        estado: '',
        mes: '',
        ano: '',
        fullDate: '',
      },
      valores: {
        total: 0,
        multa: 0,
        totalExtenso: '',
        parcelas: [
          {
            valor: 0,
            data: '',
          },
        ],
      },
      tipoPessoas: [],
      proposta: {
        numero: '',
      },
      testemunhas: [
        {
          nome: '',
          tel: '',
          cidade: '',
          endereco: '',
          rg: '',
          cpf: '',
        },
      ],
      tipo: 'alpha',
      cidade: '',
      ref_cliente: '',
      dt_chegada: '',
      ano_corrente: '',
      infoCidadeMetrics: {},
      problemasComDatas: false,
      finalizarOrcamento: false,
      renderizarContrato: false,
      gerarDownload: false,
      salvarOrcamento: false,
      dataHjExtenso: new Date().toLocaleString('pt-br', {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      }),
      individual: false,
      procurandoCep: false,
      numContrato: '',
      botaoDownload: true,
      tabsControle: 'contratante',
      contratoSalvo: {},
    };
  }

  componentWillMount() {
    axios.defaults.headers.common = {
      Authorization: sessionStorage.getItem('token'),
    };
  }

  criarLog(tipo, erro, mensagem) {
    new TelegramLog().novo(tipo, [
      JSON.stringify(erro),
      mensagem,
      `Orçamento nº: ${this.props.orcamento.id}`,
      `usuarioLogado: ${
        JSON.parse(sessionStorage.getItem('usuarioLogado'))[0].Login
      }`,
    ]);
  }

  async componentDidMount() {
    let usuarioLogado = JSON.parse(sessionStorage.getItem('usuarioLogado'))[0],
      orcamento = this.props.orcamento,
      cliente = orcamento.cliente,
      {
        valores,
        proposta,
        turma,
        cidade,
        contratantes,
        contratada,
      } = this.state,
      hj = this.splitReverseJoinToEnglish(this.toLocalePt(new Date())),
      ano_corrente = String(new Date().getFullYear()).substring(2, 4);

    if (cliente.contatos.length > 0) {
      orcamento.observacao = orcamento.cliente.Obs;

      valores.total = Number(orcamento.precoTotal.toFixed(2));
      valores.multa = orcamento.precoTotal * 0.3;
      valores.totalExtenso = extenso(
        orcamento.precoTotal.toLocaleString('pt-BR')
      );
      valores.parcelas[0].valor = valores.total;
      valores.parcelas[0].data = hj;

      proposta.numero = orcamento.id;
      //turma
      turma.curso = cliente.curso;
      turma.faculdade = cliente.Instituicao;
      turma.estado = this.state.estadosJson.find((estado) => {
        return estado.sigla === cliente.SiglaEstado;
      });
      turma.estado = turma.estado !== undefined ? turma.estado.nome : '';
      //cidade
      cidade = cliente.Cidade;
      //contratante
      contratantes[0].id = cliente.contatos[0].idContato;
      contratantes[0].nome = cliente.contatos[0].Nome;
      contratantes[0].endereco = cliente.Endereco;
      contratantes[0].cpf = cliente.contatos[0].CPF;
      contratantes[0].rg = cliente.contatos[0].RG;
      contratantes[0].telCel = cliente.contatos[0].Celular;
      contratantes[0].telRes = cliente.contatos[0].Fixo;
      contratantes[0].email = cliente.contatos[0].email;
      contratada.representante = orcamento.NomeVendedor;
      contratada.carteira = usuarioLogado.carteiraTrabalho;
      contratada.estado = usuarioLogado.estado;
      contratada.estadoDaCarteira = usuarioLogado.estado;
      contratada.telefone = usuarioLogado.Telefone;
      contratada.cidade = usuarioLogado.cidade;
      contratada.rua = usuarioLogado.endereco;
      await this.gerarNumeroContrato(orcamento.cliente.numContrato);
      this.setState(
        {
          orcamento,
          cliente,
          cidade,
          contratantes,
          contratada,
          valores,
          individual: orcamento.pagamentoIndividual,
          ano_corrente,
          renderizarContrato: true,
        },
        () => {
          axios
            .get(`${REQUEST_URL}/contrato_orcamento/${orcamento.id}`)
            .then((response) => {
              this.carregarSalvo(response.data);
            })
            .catch((erro) => {
              this.criarLog(
                'INFO',
                erro,
                'Não existe nenhum contrato cadastrado para este orçamento!'
              );
            });
        }
      );
    }
  }

  componentDidUpdate() {
    this.bloquearInputsParcela();
  }

  carregarSalvo(contrato) {
    let contratantes = this.state.contratantes,
      tipoPessoas = ['fisica'];
    contratantes[0].id = this.state.orcamento.cliente.contatos[0].idContato;
    contratantes[0].nome = contrato.c1_nome;
    contratantes[0].endereco = contrato.c1_endereco;
    contratantes[0].cidade = contrato.c1_cep !== '' ? contrato.c1_cidade : '';
    contratantes[0].estado = contrato.c1_cep !== '' ? contrato.c1_estado : '';
    contratantes[0].bairro = contrato.c1_bairro;
    contratantes[0].CEP = contrato.c1_cep;
    contratantes[0].cpf = contrato.c1_cpf;
    contratantes[0].rg = contrato.c1_rg;
    contratantes[0].numero = contrato.c1_numero;
    contratantes[0].email = contrato.c1_email;
    contratantes[0].telCel = contrato.c1_telcel;
    contratantes[0].telRes = contrato.c1_telres;
    contratantes[0].cnpj = contrato.c1_cnpj;
    if (contratantes[0].cnpj !== null && contratantes[0].cnpj !== '') {
      tipoPessoas = ['juridica'];
    }

    if (contrato.qtdContratantes > 1) {
      let contratante2 = {
        id: this.state.orcamento.cliente.contatos[1].idContato,
        nome: contrato.c2_nome,
        endereco: contrato.c2_endereco,
        cidade: contrato.c2_cidade,
        estado: contrato.c2_estado,
        bairro: contrato.c2_bairro,
        numero: contrato.c2_numero,
        CEP: contrato.c2_cep,
        cpf: contrato.c2_cpf,
        rg: contrato.c2_rg,
        email: contrato.c2_email,
        telCel: contrato.c2_telcel,
        telRes: contrato.c2_telres,
        cnpj: contrato.c2_cnpj,
      };
      contratantes.push(contratante2);
      if (contratante2.cnpj !== null && contratante2.cnpj !== '') {
        tipoPessoas.push('juridica');
      } else {
        tipoPessoas.push('fisica');
      }
    }
    let valores = this.state.valores;
    valores.total = contrato.valorTotal;
    valores.multa = contrato.valorTotal * 0.3;
    valores.totalExtenso = extenso(contrato.valorTotal.toLocaleString('pt-BR'));

    let parcelas = [];
    if (contrato.parcelas.length === 0) {
      for (let i = 0; i < contrato.qtdParcelas; i++) {
        parcelas.push({
          valor: '500',
          data: this.splitReverseJoinToEnglish(this.toLocalePt(new Date())),
        });
      }
      let precoParcela = contrato.valorTotal / contrato.qtdParcelas;
      parcelas.forEach((parcela) => {
        parcela.valor = precoParcela;
      });
    } else {
      contrato.parcelas.forEach((parcela) => {
        parcelas.push({
          id: parcela.id,
          valor: parcela.valor,
          data: parcela.data.split('T')[0],
        });
      });
    }
    valores.parcelas = parcelas;
    let tipo = this.state.tipo;
    tipo = contrato.tipoContrato;

    let contratada = this.state.contratada;
    contratada.representante = contrato.r_nome;
    contratada.carteira = contrato.r_carteira;
    contratada.cidade = contrato.r_cidade;
    contratada.estado = contrato.r_estado;
    contratada.estadoDaCarteira = contrato.r_estadoCarteira;
    contratada.rua = contrato.r_rua;
    contratada.telefone = contrato.r_telefone;

    let turma = this.state.turma;
    if (turma.estado === undefined) {
      turma.estado = '';
    }
    let mesExtenso = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    mesExtenso.forEach((mes, i) => {
      if (mes === contrato.t_mesColacao) {
        let novoMes = '0';
        if (i + 1 < 10) {
          novoMes += i + 1;
        } else {
          novoMes = i + 1;
        }
        turma.fullDate = `${contrato.t_anoColacao}-${novoMes}-01`;
        turma.ano = contrato.t_anoColacao;
        turma.mes = contrato.t_mesColacao;
      }
    });
    let testemunhas = this.state.testemunhas;
    testemunhas[0].nome = contrato.tes1_nome;
    testemunhas[0].tel = contrato.tes1_telefone;
    testemunhas[0].cidade = contrato.tes1_cidade;
    testemunhas[0].endereco = contrato.tes1_endereco;
    testemunhas[0].rg = contrato.tes1_rg;

    if (contrato.qtdTestemunhas > 1) {
      let testemunha2 = {
        nome: contrato.tes2_nome,
        tel: contrato.tes2_telefone,
        cidade: contrato.tes2_cidade,
        endereco: contrato.tes2_endereco,
        rg: contrato.tes2_rg,
      };
      testemunhas.push(testemunha2);
    }
    let ano_corrente = this.state.ano_corrente;
    if (contrato.ano_corrente) {
      ano_corrente = contrato.ano_corrente;
    }
    this.setState({
      contratantes,
      valores,
      tipo,
      contratada,
      turma,
      testemunhas,
      ref_cliente: contrato.ref_cliente,
      dt_chegada: contrato.dt_chegada.split('T')[0],
      ano_corrente,
      tipoPessoas,
    });
  }

  async salvarContrato() {
    let validou = this.validarContrato();
    if (validou === true) {
      this.setState({ salvarOrcamento: true });
      if (!this.state.problemasComDatas) {
        let contrato = {
          c1_id: this.state.contratantes[0].id,
          c1_bairro: this.state.contratantes[0].bairro,
          c1_cep: this.state.contratantes[0].CEP,
          c1_cidade: this.state.contratantes[0].cidade,
          c1_cpf: this.state.contratantes[0].cpf,
          c1_email: this.state.contratantes[0].email,
          c1_endereco: this.state.contratantes[0].endereco,
          c1_estado: this.state.contratantes[0].estado,
          c1_nome: this.state.contratantes[0].nome,
          c1_numero: this.state.contratantes[0].numero,
          c1_rg: this.state.contratantes[0].rg,
          c1_telcel: this.state.contratantes[0].telCel,
          c1_telres: this.state.contratantes[0].telRes,
          c1_cnpj: this.state.contratantes[0].cnpj,

          orcamento: this.props.orcamento.id,
          qtdContratantes: this.state.contratantes.length,
          qtdParcelas: this.state.valores.parcelas.length,
          qtdTestemunhas: this.state.testemunhas.length,

          r_carteira: this.state.contratada.carteira,
          r_cidade: this.state.contratada.cidade,
          r_estado: this.state.contratada.estado,
          r_estadoCarteira: this.state.contratada.estadoDaCarteira,
          r_nome: this.state.contratada.representante,
          r_rua: this.state.contratada.rua,
          r_telefone: this.state.contratada.telefone,
          t_anoColacao: this.state.turma.ano,
          t_mesColacao: this.state.turma.mes,
          tes1_cidade: this.state.testemunhas[0].cidade,
          tes1_endereco: this.state.testemunhas[0].endereco,
          tes1_nome: this.state.testemunhas[0].nome,
          tes1_rg: this.state.testemunhas[0].rg,
          tes1_telefone: this.state.testemunhas[0].tel,
          tes1_cpf: this.state.testemunhas[0].cpf,

          tipoContrato: this.state.tipo,
          valorTotal: this.state.valores.total,
        };
        if (this.state.contratantes.length > 1) {
          contrato.c2_id = this.state.contratantes[1].id;
          contrato.c2_bairro = this.state.contratantes[1].bairro;
          contrato.c2_cep = this.state.contratantes[1].CEP;
          contrato.c2_cidade = this.state.contratantes[1].cidade;
          contrato.c2_cpf = this.state.contratantes[1].cpf;
          contrato.c2_email = this.state.contratantes[1].email;
          contrato.c2_endereco = this.state.contratantes[1].endereco;
          contrato.c2_estado = this.state.contratantes[1].estado;
          contrato.c2_nome = this.state.contratantes[1].nome;
          contrato.c2_numero = this.state.contratantes[1].numero;
          contrato.c2_rg = this.state.contratantes[1].rg;
          contrato.c2_telcel = this.state.contratantes[1].telCel;
          contrato.c2_telres = this.state.contratantes[1].telRes;
          contrato.c2_cnpj = this.state.contratantes[1].cnpj;
        }
        if (this.state.testemunhas.length > 1) {
          contrato.tes2_cidade = this.state.testemunhas[1].cidade;
          contrato.tes2_endereco = this.state.testemunhas[1].endereco;
          contrato.tes2_nome = this.state.testemunhas[1].nome;
          contrato.tes2_rg = this.state.testemunhas[1].rg;
          contrato.tes2_telefone = this.state.testemunhas[1].tel;
          contrato.tes2_cpf = this.state.testemunhas[1].cpf;
        }
        contrato.dt_chegada = this.state.dt_chegada;
        contrato.ref_cliente = this.state.ref_cliente;
        if (contrato.ref_cliente !== '') {
          this.gerarNumeroContrato(contrato.ref_cliente);
        }
        // Atualizar contato dos contratantes
        axios
          .put(
            `${REQUEST_URL}/cliente/contato/${this.state.contratantes[0].id}`,
            {
              Nome: contrato.c1_nome,
              email: contrato.c1_email,
              Celular: contrato.c1_telcel,
              Fixo: contrato.c1_telres,
              CPF: contrato.c1_cpf,
              RG: contrato.c1_rg,
              CNPJ: contrato.c1_cnpj,
              ContatoPrinc: 'SIM',
            }
          )
          .then(async () => {
            if (this.state.contratantes.length > 1) {
              if (this.state.contratantes[1].id !== undefined) {
                axios
                  .put(
                    `${REQUEST_URL}/cliente/contato/${this.state.contratantes[1].id}`,
                    {
                      Nome: contrato.c2_nome,
                      email: contrato.c2_email,
                      Celular: contrato.c2_telcel,
                      Fixo: contrato.c2_telres,
                      CPF: contrato.c2_cpf,
                      RG: contrato.c2_rg,
                      CNPJ: contrato.c2_cnpj,
                      ContatoPrinc: 'NAO',
                    }
                  )
                  .catch((erro) => {
                    this.criarLog(
                      'ERROR',
                      erro,
                      'Não foi possível atualizar as informações de contato do contratante 2'
                    );
                    toast.error(
                      'Não foi possível atualizar as informações de contato do contratante 2'
                    );
                  });
              } else {
                axios
                  .post(`${REQUEST_URL}/cliente/contato/`, {
                    Nome: contrato.c2_nome,
                    email: contrato.c2_email,
                    Celular: contrato.c2_telcel,
                    Fixo: contrato.c2_telres,
                    CPF: contrato.c2_cpf,
                    RG: contrato.c2_rg,
                    IdCliente: this.props.orcamento.cliente.codCliente,
                    CNPJ: contrato.c2_cnpj,
                    ContatoPrinc: 'NAO',
                  })
                  .then((clienteCadastrado) => {
                    contrato.c2_id = clienteCadastrado.data.insertId;
                    let contratantes = Object.assign(
                      [],
                      this.state.contratantes
                    );
                    contratantes[1].id = clienteCadastrado.data.insertId;
                    this.setState({ contratantes });
                  })
                  .catch((erro) => {
                    this.criarLog(
                      'ERROR',
                      erro,
                      'Não foi possível cadastrar as informações de contato do contratante 2'
                    );
                    toast.error(
                      'Não foi possível cadastrar as informações de contato do contratante 2'
                    );
                  });
              }
            }
            contrato.ano_corrente = parseInt(this.state.ano_corrente);
            axios
              .post(`${REQUEST_URL}/contrato_orcamento`, { contrato })
              .then(async (response) => {
                this.setState({
                  salvarOrcamento: false,
                  botaoDownload: false,
                  contratoSalvo: contrato,
                });
                await this.salvarParcelas();
                toast.success('Contrato atualizado com sucesso!');
              })
              .catch((erro) => {
                this.criarLog(
                  'ERROR',
                  erro,
                  'Erro ao salvar o contrato. Tente novamente!'
                );
                toast.error('Erro ao salvar o contrato. Tente novamente!');
                this.setState({ salvarOrcamento: false });
              });
          })
          .catch((erro) => {
            this.criarLog(
              'ERROR',
              erro,
              'Não foi possível atualizar as informações de contato do contratante 1'
            );
            toast.error(
              'Não foi possível atualizar as informações de contato do contratante 1'
            );
            this.setState({ salvarOrcamento: false });
          });
      }
    } else {
      toast.error(validou);
    }
  }

  async gerarNumeroContrato(numContrato = null) {
    if (numContrato === null) {
      await axios
        .get(REQUEST_URL + '/orcamentos/numContrato/last')
        .then(async (response) => {
          let id = response.data.length > 0 ? response.data[0].idOrc : -1,
            numContrato =
              response.data.length > 0 ? response.data[0].numContrato : null;
          if (id !== this.props.orcamento.id) {
            if (numContrato === null) {
              numContrato = 30000;
            } else {
              await axios
                .post(`${URL_CONTRATO_API}/num-contract`)
                .then((response) => {
                  numContrato = response.data.contract;
                })
                .catch((erro) => {
                  this.criarLog(
                    'ERROR',
                    erro,
                    'Erro ao gerar o número do contrato deste orçamento pela API de contratos!'
                  );
                  toast.error(
                    'Erro ao gerar o número do contrato deste orçamento'
                  );
                });
            }
            await axios
              .put(
                REQUEST_URL +
                  '/orcamento/numContrato/' +
                  this.props.orcamento.id,
                { numContrato: numContrato }
              )
              .then((response) => {
                let orcamento = Object.assign({}, this.props.orcamento);
                orcamento.cliente.numContrato = numContrato;
                this.setState({ orcamento, numContrato });
                this.props.atualizarNumeroContrato(numContrato);
              })
              .catch((erro) => {
                this.criarLog(
                  'ERROR',
                  erro,
                  'Erro ao atualizar o número do contrato'
                );
                toast.error(
                  'Erro ao gerar o número do contrato deste orçamento'
                );
              });
          } else {
            let orcamento = Object.assign({}, this.props.orcamento);
            this.setState({ orcamento, numContrato });
            this.props.atualizarNumeroContrato(numContrato);
            axios
              .put(
                `${REQUEST_URL}/orcamento/${this.props.orcamento.id}/numContrato`,
                { numContrato: numContrato }
              )
              .catch((erro) => {
                this.criarLog(
                  'ERROR',
                  erro,
                  'Erro ao atualizar o número do contrato'
                );
              });
          }
        })
        .catch((erro) => {
          this.criarLog('ERROR', erro, 'Erro ao consultar o último contrato');
          toast.error('Erro ao consultar o último contrato');
        });
    } else {
      let orcamento = Object.assign({}, this.state.orcamento);
      this.setState({ orcamento, numContrato });
      this.props.atualizarNumeroContrato(numContrato);
      axios
        .put(
          `${REQUEST_URL}/orcamento/${this.props.orcamento.id}/numContrato`,
          { numContrato: numContrato }
        )
        .catch((erro) => {
          this.criarLog(
            'ERROR',
            erro,
            'Erro ao atualizar o número do contrato'
          );
        });
    }
  }

  salvarParcelas() {
    let novasParcelas = this.state.valores.parcelas;
    novasParcelas.forEach((parcela, i) => {
      if (parcela.id !== undefined) {
        axios
          .put(`${REQUEST_URL}/contrato/parcela/${parcela.id}`, parcela)
          .catch((erro) => {
            this.criarLog(
              'ERROR',
              erro,
              `Erro ao alterar as informações da parcela ${i + 1}!`
            );
            toast.error(`Erro ao alterar as informações da parcela ${i + 1}!`);
          });
      } else {
        axios
          .get(`${REQUEST_URL}/contrato_orcamento/${this.state.orcamento.id}`)
          .then((data) => {
            parcela.contrato = data.data.id;
            axios
              .post(`${REQUEST_URL}/contrato/parcela/`, parcela)
              .then((data) => {
                parcela.id = data.data.insertId;
              })
              .catch((erro) => {
                this.criarLog(
                  'ERROR',
                  erro,
                  `Erro ao cadastrar a parcela ${i + 1}`
                );
                toast.error(`Erro ao cadastrar a parcela ${i + 1}!`);
              });
          })
          .catch((erro) => {
            this.criarLog(
              'ERROR',
              erro,
              'Não é possível listar as parcelas de um contrato inexistente!'
            );
            toast.error(
              `Não é possível listar as parcelas de um contrato inexistente!`
            );
          });
      }
    });
    this.setState({
      valores: { ...this.state.valores, parcelas: novasParcelas },
    });
  }

  async gerarProposta(comValor) {
    var proposta = new jsPDF({ lineHeight: 1.8 }),
      lineHeight = 7,
      marginLeft = 10,
      marginRight = 20,
      pageWidth = proposta.internal.pageSize.getWidth(),
      pageHeight = proposta.internal.pageSize.getHeight(),
      textOptions = {
        maxWidth: pageWidth - (marginLeft + marginRight),
        align: 'justify',
      },
      line = 10,
      imgPropostaData = getPropostaImage();
    proposta.setFontSize(11);
    proposta.setFont('times');
    proposta.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
    line += lineHeight * 4;
    proposta.setFontType('bold');
    proposta.text(marginLeft, line, `Proposta nº ${this.state.orcamento.id}`);
    proposta.setFontType('normal');
    proposta.text(
      marginLeft + 125,
      line,
      `${new Date().toLocaleString('pt-br', {
        weekday: 'long',
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      })}`
    );
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `À comissão de formatura do curso: ${this.state.orcamento.cliente.curso}`
    );
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `Instituição: ${this.state.orcamento.cliente.Instituicao}`
    );
    line += lineHeight;
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `Conforme solicitação, apresentamos abaixo nossa estimativa de preços conforme especificações e condições gerais.`,
      textOptions
    );
    line += lineHeight;
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `Arte: Projeto designer alpha (company)`
    );
    proposta.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Resposta do Briefing no site da empresa, onde será obtido um conjunto de respostas que traduzem da melhor forma os anseios e intenções da turma.`,
      {
        maxWidth: pageWidth - (marginLeft + marginRight + 15),
        align: 'justify',
      }
    );
    proposta.text(
      marginLeft + 5,
      (line += lineHeight * 2),
      `* Pesquisa de imagens que abrangem a tendência.`
    );
    proposta.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Criação, editoração e diagramação.`
    );
    proposta.text(
      marginLeft + 5,
      (line += lineHeight),
      `* Duas provas online no site da Alpha convites, para revisão, alterações e validações pela comissão de formatura.`
    );

    line += lineHeight;
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `Itens orçados nesta proposta:`,
      textOptions
    );

    line += lineHeight;
    let tablebody = [];
    this.state.orcamento.itens.map((item) => {
      if (item.qtd > 0 && item.visivelProposta) {
        tablebody.push([
          `${item.nome} ${item.detalhes} ${
            item.acabamentos.length > 0
              ? 'C/ ' +
                item.acabamentos
                  .map((a) => {
                    return a.DescProoduto;
                  })
                  .join(', ')
              : ' '
          }` + '\n',
          item.qtd,
        ]);
      }
    });
    let head = ['ITENS', 'QTD'];
    proposta.autoTable({
      head: [head],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      body: tablebody,
      startY: line,
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: {
          cellPadding: { top: 0, right: 5, bottom: 0, left: 0 },
          cellWidth: 100,
        },
      },
      theme: 'grid',
    });
    line = proposta.autoTable.previous.finalY + 10;

    let tablebodybrinde = [];
    this.props.kits.map((kit) => {
      if (kit.tipo === 'brinde') {
        if (comValor) {
          tablebodybrinde.push([`${kit.nome}`, kit.qtd]);
        } else {
          tablebodybrinde.push([`${kit.nome}`, kit.qtd]);
        }
      }
    });
    let headbrinde = ['BRINDES', 'QTD'];
    if (tablebodybrinde.length > 0) {
      proposta.autoTable({
        head: [headbrinde],
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
        body: tablebodybrinde,
        startY: line,
        styles: { overflow: 'linebreak' },
        columnStyles: {
          0: {
            cellPadding: { top: 0, right: 5, bottom: 0, left: 0 },
            cellWidth: 100,
          },
        },
        theme: 'grid',
      });
      line = proposta.autoTable.previous.finalY + 10;
    }

    proposta.addPage();
    proposta.addImage(imgPropostaData, 'JPEG', 0, 0, pageWidth, pageHeight);
    line = 25;
    proposta.text(
      marginLeft,
      (line += lineHeight),
      `Kits avulsos e combos gerados:`,
      textOptions
    );

    line += lineHeight;
    tablebody = [];
    this.props.kits.map((kit) => {
      if (kit.tipo !== 'brinde') {
        if (comValor) {
          tablebody.push([
            `${kit.nome}`,
            kit.qtd,
            `R$:${this.formatReal(kit.preco, -1)}`,
            `R$:${this.formatReal(kit.preco * kit.qtd, -1)}`,
          ]);
        } else {
          tablebody.push([`${kit.nome}`, kit.qtd]);
        }
      }
    });
    head = comValor ? ['ITENS', 'QTD', 'UN', 'TOTAL'] : ['ITENS', 'QTD'];
    proposta.autoTable({
      head: [head],
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
      body: tablebody,
      startY: line,
      styles: { overflow: 'linebreak' },
      columnStyles: {
        0: {
          cellPadding: { top: 0, right: 5, bottom: 0, left: 0 },
          cellWidth: 100,
        },
      },
      theme: 'grid',
    });
    line = proposta.autoTable.previous.finalY + 10;

    line += lineHeight;

    let temCombo = false;
    this.props.kits.forEach((kit) => {
      if (kit.tipo === 'kit') {
        if (!temCombo) {
          proposta.text(
            marginLeft,
            (line += lineHeight),
            `Descrição dos combos:`,
            textOptions
          );
          temCombo = true;
        }
        proposta.text(
          marginLeft + 5,
          (line += lineHeight),
          `${kit.nome} é composto por:`,
          {
            maxWidth: pageWidth - (marginLeft + marginRight + 15),
            align: 'justify',
          }
        );
        let itensDoCombo = '';
        kit.itens.forEach((item) => {
          itensDoCombo += `${item.qtd} ${item.nome}; `;
        });
        proposta.text(marginLeft + 10, (line += lineHeight), itensDoCombo, {
          maxWidth: pageWidth - (marginLeft + marginRight + 15),
          align: 'justify',
        });
        line += lineHeight;
      }
    });

    if (this.state.possuiFrete) {
      proposta.text(
        marginLeft,
        (line += lineHeight),
        'Frete terreste por conta do fornecedor'
      );
    }
    if (comValor) {
      proposta.text(
        marginLeft + 150,
        (line += lineHeight * 2),
        `TOTAL: ${this.formatReal(this.state.valores.total)}`
      );
      proposta.text(
        `(${this.state.valores.totalExtenso})`,
        marginLeft + 183.5,
        (line += lineHeight),
        {
          maxWidth: pageWidth - (marginLeft + marginRight + 15),
          align: 'right',
        }
      );
    }
    proposta.text(
      marginLeft + 113,
      (line += lineHeight),
      `Condições e forma de pagamento a combinar`
    );
    proposta.text(
      marginLeft + 141.5,
      (line += lineHeight),
      `Proposta válida por 15 dias`
    );
    line += lineHeight;
    let observacao = [];
    observacao =
      this.state.orcamento.cliente.Obs !== undefined &&
      this.state.orcamento.cliente.Obs !== ''
        ? this.state.orcamento.cliente.Obs.split('\n')
        : '';
    if (observacao.length > 0 && observacao[0] !== '') {
      proposta.text(marginLeft, (line += lineHeight), `Observação:`);
      observacao.forEach((linha) => {
        proposta.text(marginLeft, (line += lineHeight), linha, textOptions);
        if (linha.length > 80) line += lineHeight;
      });
    }
    proposta.text(
      marginLeft,
      pageHeight - 10,
      `Representante: ${this.state.orcamento.NomeVendedor} `
    );

    return proposta;
  }

  async downloadProposta(tipo) {
    var doc = new jsPDF({ lineHeight: 1.8 });
    var imgData = getLogoAlpha();
    var imgUltrapressData = getUltrapress();
    var imgBetaData = getBetaImage();
    doc.setFontSize(11);
    let lineHeight = 7;
    let marginLeft = 10;
    let marginRight = 10;
    let pageWidth = doc.internal.pageSize.getWidth();
    let pageHeight = doc.internal.pageSize.getHeight();
    let textOptions = {
      maxWidth: pageWidth - (marginLeft + marginRight),
      align: 'justify',
    };
    doc.setFont('times');
    //############################################################################ PAGINA 1 ###############################################################################################################
    doc.addImage(imgData, 'JPEG', 10, 5, 30, 20);
    if (this.state.tipo === 'beta') {
      doc.addImage(imgBetaData, 'JPEG', 110, 5, 100, 20);
    } else {
      doc.addImage(imgUltrapressData, 'JPEG', 100, 5, 100, 15);
    }
    let line = 30;
    doc.setFontType('bold');
    doc.text(marginLeft, line, 'Contratantes: ');
    doc.setFontType('normal');
    this.state.contratantes.forEach((contratante) => {
      doc.setFontType('bold');
      doc.text(
        marginLeft,
        (line += lineHeight * 1.2),
        'Nome: ' + contratante.nome
      );
      doc.setFontType('normal');
      doc.text(123, line, 'CPF: ' + contratante.cpf);
      doc.text(170, line, 'RG: ' + contratante.rg);
      doc.text(
        marginLeft,
        (line += lineHeight),
        'Endereço: ' + contratante.endereco
      );
      doc.text(123, line, 'Bairro: ' + contratante.bairro);
      doc.text(
        marginLeft,
        (line += lineHeight),
        'Tel. Res. : ' + contratante.telRes
      );
      doc.text(123, line, 'Tel. Cel. : ' + contratante.telCel);
      doc.text(
        marginLeft,
        (line += lineHeight),
        'E-mail: ' + contratante.email
      );
      doc.text(
        marginLeft,
        (line += lineHeight),
        'Cidade/Est. : ' + contratante.cidade + '/' + contratante.estado
      );
      doc.text(123, line, 'CEP: ' + contratante.CEP);
    });

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight + 2), 'Contratada:');
    doc.setFontType('normal');

    let empresa = '';
    if (this.state.tipo === 'alpha') {
      empresa =
        'Ultrapress Editora Gráfica Ltda., pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 00.913.039/0001-08, inscrição estadual nº 518.01966-00, com sede em Bandeirantes, Estado do Paraná, Av. Bandeirantes 1428';
    } else {
      empresa =
        'Beta Imagem Empresa Fotográfica Ltda., pessoa jurídica de direito privado, inscrita no CNPJ/MF sob o nº 07.093.119/0001-03, inscrição estadual nº 903.29089-74, com sede em Bandeirantes, Estado do Paraná, Rua São Paulo, 1990 - Centro';
    }

    doc.text(
      marginLeft,
      (line += lineHeight),
      `${empresa}, aqui representada pelo Sr ${this.state.contratada.representante} ` +
        `  Tel ${this.state.contratada.telefone} Brasileiro, representante comercial, portador da Carteira Profissional nº ${this.state.contratada.carteira},` +
        ` expedida pelo Conselho de Representantes Comerciais do Estado ${this.state.contratada.estadoDaCarteira},` +
        `domiciliado na cidade ${this.state.contratada.cidade}, Estado do ${this.state.contratada.estado},` +
        `com residência na Rua ${this.state.contratada.rua}, conforme poderes conferidos por contrato próprio.` +
        'As partes acima identificadas ajustam entre si contrato de prestação de serviço de confecção de produto(s) para eventos de Colação de Grau, de acordo com as cláusulas e condições a seguir estabelecidas.',
      textOptions
    );
    doc.setFontType('bold');
    doc.text(
      60,
      (line += lineHeight * 8),
      'Objeto do Contrato, Serviço e Especificações'
    );
    doc.setFontType('normal');
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `Cláusula 01 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                   O objeto deste contrato é a prestação de serviço de impressão, montagem e entrega dos produtos especificados: `,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(
      marginLeft,
      (line += lineHeight * 2),
      `CONFORME PROPOSTA NÚMERO ${this.state.proposta.numero}`
    );
    doc.setFontType('normal');
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `§ Único`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `              A presente contratação foi precedida de consulta feita pelos Contratantes à Turma de formandos: alunos ` +
        `do Curso de ${this.state.turma.curso}` +
        ` , da Universidade/Faculdade  ${this.state.turma.faculdade}, Estado de ${this.state.turma.estado}` +
        ` , com eventos de Colação de Grau previstos para o mês de ${this.state.turma.mes}` +
        ` do ano ${this.state.turma.ano}`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 4), 'PREÇO E PAGAMENTO');
    doc.setFontType('normal');
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `Cláusula 02 - `);
    doc.setFontType('normal');
    let condicoes = '';
    if (!this.state.individual) {
      condicoes = 'nas seguintes condições:';
    } else {
      condicoes =
        'sendo os valores arrecadados junto aos formandos individualmente por meio de contrato celebrado entre eles e a Contratada (Alpha Editora).';
    }
    doc.text(
      marginLeft,
      line,
      `                      Preço: Pelos serviços prestados, os Contratantes pagarão à Contratada a importância de R$ ${this.formatReal(
        this.state.valores.total
      )}` + `( ${this.state.valores.totalExtenso}), ${condicoes}`,
      textOptions
    );
    line += lineHeight;
    if (!this.state.individual) {
      this.state.valores.parcelas.forEach((parcela, i) => {
        doc.text(
          marginLeft,
          (line += lineHeight),
          `R$: ${parcela.valor} em ${parcela.data
            .split('-')
            .reverse()
            .join('/')};`
        );
      });
    }

    let clausula2 = 1;

    if (this.state.individual) {
      doc.setFontType('bold');
      doc.text(marginLeft, (line += lineHeight * 2), `§ ${clausula2}º -`);
      doc.setFontType('normal');
      doc.text(
        marginLeft,
        line,
        `           Os valores das parcelas pactuadas, serão corrigidas monetariamente pelo INPC (índice geral de preço do mercado), aplicando os percentuais do período pretérito às parcelas subseqüentes, no início de cada ano.`,
        textOptions
      );
      clausula2++;
    }

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ ${clausula2}º -`);
    clausula2++;
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `           Para os efeitos próprios, os preços unitário dos produtos, conforme seu tipo: ` +
        `PROPOSTA NÚMERO ${this.state.proposta.numero}`,
      textOptions
    );
    //########################################################################### PAGINA 2 ###############################################################################################################
    doc.addPage();
    line = 10;
    doc.setFontType('bold');
    doc.text(marginLeft, line, `§ ${clausula2}º - `);
    clausula2++;
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `       Em caso de redução de quantidade ou alterações na composição do modelo escolhido, os Contratantes deverão ` +
        `solicitar ao Setor de Orçamento da Contratada os novos valores dos produtos, antes dos mesmos serem enviados ao` +
        `setor de produção, considerando-se sempre, o cronograma de que trata a cláusula nº05, adiante.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ ${clausula2}º - `);
    clausula2++;
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `        Em caso de aumento de quantidade de produtos os Contratantes deverão comunicar-se com a Contratada antes ` +
        `do momento da liberação do mesmo para a impressão.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ ${clausula2}º - `);
    clausula2++;
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `      As despesas com o seguro e o frete do(s) produto(s) até o local do destino, são de responsabilidade dos Contratantes.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ ${clausula2}° -`);
    clausula2++;
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `        A Contratada poderá fornecer aos Contratantes, por liberdade exclusiva sua, os seguintes brindes abaixo relacionados:  PROPOSTA NÚMERO ${this.state.proposta.numero}`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ ${clausula2}° -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `           Os brindes discriminados no parágrafo acima será entregue no mesmo prazo dos convites, não podendo os Contratantes,` +
        ` sobre qualquer hipótese, alegar descumprimento do contrato quando versar exclusivamente sobre os referidos brindes, por ser, como acima informado, custeado exclusivamente pela Contratada.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Cláusula 03 - `);
    doc.setFontType('normal');

    doc.text(
      marginLeft,
      line,
      `                    Pagamentos: Todos os pagamentos deverão ser feitos só e diretamente à Contratada, mediante boletos bancários ou depósitos bancários identificados, sem prejuízo da emissão de duplicatas, com praça de pagamento em Bandeirantes, PR.` +
        ` São vedados pagamentos a terceiros, dentre os quais o representante comercial.`,
      textOptions
    );

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ Primeiro -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                Caso sejam ultrapassados 12(doze) meses, após o fechamento do contrato, as parcelas sofrerão correção monetária pelo INPC, acumulado no período.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ Segundo -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                  Sem prejuízo da cláusula acima, caso haja atraso no pagamento implicará no pagamento em multa de 2% (dois por cento) do valor da parcela, monetariamente corrigida, mais juros pro rata die de 1% (um por cento) ao mês (Cf. CDC Lei nº8.078, de 11.09.90)`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(
      marginLeft,
      (line += lineHeight * 3),
      `OBRIGAÇÕES DOS CONTRATANTES`
    );
    doc.text(marginLeft, (line += lineHeight), `Cláusula 04 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                 Da Contratação: O presente instrumento e, bem assim, seus eventuais aditivos, uma vez assinados pelos Contratantes e o representante da Contratada,` +
        ` serão a esta última imediatamente remetidos, com comprovante de recebimento, para seu exame e aprovação. A correspondente resposta será formalmente comunicada` +
        ` aos Contratantes no prazo de 30 (trinta) dias úteis, incluído o dia de sua postagem.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 4), `§ Único`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `             Ocorrido a aprovação, a Contratada comunicá-la-á aos Contratantes, enviados lhes, via e-mail ou correio postal, senha de acesso ao seu site (www.alphaeditora.com.br).`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 05 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                      Do Cronograma: Realizada a contratação, como anexo essencial deste contrato, a Contratada e Contratantes, em conjunto, definirão o cronograma das etapas/ atividades a serem cumpridas pelas partes,` +
        ` com o compromisso de fiel cumprimento dos prazos nele estabelecidos e fixados no calendário civil.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ 1º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         Aprovado o cronograma, após eventuais ajustes solicitados pelos Contratantes, ele permanecerá exposto no site da Contratada, marcando o tempo e sucessão das etapas/atividades para as partes.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 2º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         O atraso no comprimento da etapa/atividade do cronograma sujeitará o atrasador ao pagamento de multa diária específica de 0,10% (dez centésimos por cento)` +
        ` do valor do contratado, monetariamente corrigido. Se o atraso for da Contratada, o montante será considerado em favor dos Contratantes para desconto quando do` +
        ` pagamento da última parcela do preço; e, ao contrário, se o atraso for dos Contratantes, o montante será cobrado pela Contratada juntamente com o valor da última parcela do preço,` +
        ` incluindo no respectivo boleto bancário.`,
      textOptions
    );
    //########################################################################### PAGINA 3 ################################################################################################################
    doc.addPage();
    line = 10;
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `§ 3º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         Se, porém, o atraso inviabilizar o objeto do contrato (recepção dos convites em tempo hábil para distribuição), ele será considerado rompido por culpa do atrasador, aplicando-se-lhe a penalidade prevista na cláusula nº 16 (dezesseis).`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 4º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         No interesse de uma ou outra das partes, poderá ocorrer modificação do cronograma, se possível.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `§ 5º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         Sempre que o atraso se der por motivo de força maior ou caso fortuito, o cronograma será refeito pelas partes, em conjunto, para fixação de novas datas para o cumprimento das etapas/atividades.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 06 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                    Do layout: Simultaneamente com a aprovação do cronograma, a Contratada enviará aos Contratantes consulta, por meio de questionário, para coleta de suas informações para criação do layout.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 1º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `         O layout será desenvolvido pelo designer da Contratada e publicado no seu site para conhecimento, revisão e aprovação; e, se verificando pedido de retificação, o layout será revisto pelo designer e, em seguida, novamente publicado para anuência dos Contratantes e, por fim, as conclusivas revisões.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ 2º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `          Desde que o cronograma o permita, além das duas primeiras versões publicadas do layout, para cada nova versão será cobrado o preço adicional de R$500,00 (quinhentos reais).`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 3º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `          Layout diferenciado, solicitado pelos Contratantes, que requeira pesquisa de tema e de ilustração, mais produção fotográfica específica, será realizado mediante adicional em seu custo financeiro e desde que possível pelo cronograma.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 4º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `          Ao presente instrumento de contrato vincula-se um anexo, em impressão própria, a ficha de detalhamento, sendo assinadas pelas partes em duas vias, que será utilizado para especificação de todos os detalhes dos serviços ora discutidos e contratados,` +
        ` tais como: traços das capas dos produtos; característica da capa; tipo de papel e de gramatura; quantidade de páginas; quantidade de fotos e sua distribuição; de vegetais e sua colocação; cor e tipo de diagramação para layout, etc.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 4), `Cláusula 07 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                     Do Fornecimento de Dados e Materiais: Por meio do seu site, a Contratada disponibiliza aos Contratantes um banco de imagens e de textos, para escolher dos mesmos; fora desse, qualquer imagem, ilustração, foto, texto, etc.,` +
        ` que os Contratantes queiram incluir no(s) produto(s), deverão entregá-los de modo regular: no tempo certo e, se for o caso, acompanhados da competente autorização do seu autor ou titular, preservados os direitos autorais. Além do site da Contratada,` +
        ` os Contratantes poderão valer-se de compact disk ou fotos digitalizadas, conforme o caso.`,
      textOptions
    );

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 5), `§ 1º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `           Afora os dados disponibilizados pela Contratada, os Contratantes respondem pelo fornecimento e pela boa qualidade de todo material fotográfico que queiram incluir no corpo do(s) produto(s). O material deve ser entregue em mídia digital de resolução mínima de 3.000 x 2.000 pixels.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ 2º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `           A Contratada reserva-se a faculdade de reprovar o material na hipótese de constatar qualidade inferior a necessária para a boa execução do serviço objeto deste contrato.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 08 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                      Da Produção: Compete à Contratada a diagramação e a criação do layout do(s) produto(s), enquanto que a sua completa revisão, em especial dos textos, dos nomes e das fotos pessoais, é de atribuição única e exclusiva dos Contratantes,` +
        ` ficando a Contratada isenta de qualquer responsabilidade por erros não apontados pelos Contratantes, dentro das especificações já informadas nas cláusulas 10 e 11.`,
      textOptions
    );

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 4), `Cláusula 09 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                      Quando o prazo entre a contratação e entrega do produto for menor que noventa (90) dias, o procedimento de produção será o simplificado, sendo facultado aos Contratantes escolher um dos modelos do produto constantes do catálogo da Contratada,` +
        ` personalizando-se apenas os nomes, as fotos e as solenidades, com uma prova eletrônica, autorização e, em seguida, impressão.`,
      textOptions
    );

    //########################################################################### PAGINA 4 ################################################################################################################
    doc.addPage();
    line = 10;
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `CONDIÇÕES GERAIS`);
    doc.setFontType('normal');
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `Cláusula 10 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                       Sob pena de não poderem opor vício do produto, sempre que os Contratantes acessarem o site da Contratada, para fornecer dados ou fazerem correções,` +
        ` rotinas de sua obrigação, como por exemplos, a escolha de textos, fornecimento de nomes de integrantes do corpo docente ou discente, etc.,` +
        ` uma vez por eles efetivadas e aprovadas as implementações, deverão imprimir as páginas acessadas, guardando-as consigo para futura demonstração de fatos do seu interesse, caso necessário.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 5), `Cláusula 11 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Embora a Contratada empregue ferramentas de âmbito internacional para referência de cores, como escala pantone e cmyk, nem sempre há coincidência absoluta de tonalidades de cores,` +
        ` nas provas apresentadas aos Contratantes via online "mídia monitores", devido aos diferentes padrões de fábrica na calibragem, e ainda em provas via física, "impressoras a laser",` +
        ` por se tratar de processos diferentes de impressão entre a prova e o(s) produto(s) final. Razões pelas quais a Contratada não se responsabiliza por tonalidades destoantes.` +
        ` A garantia de coincidência absoluta, se exigida, será prestada mediante custo adicional em uma "prova física com fidelidade de cor", mediante prévio pagamento, se assim requerer os Contratantes.`,
      textOptions
    );

    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 7), `Cláusula 12 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `               Para os efeitos próprios, inclusive os jurídicos, a este contrato também se agrega o site www.alphaeditora.com.br, endereço eletrônico da Contratada, para uso exclusivo das partes com vistas ao cumprimento deste contrato, a cujas inserções elas se vinculam.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Cláusula 13 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                     O layout do(s) produto(s) objeto deste contrato pertence sempre à Contratada.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `Cláusula 14 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          A Contratada reserva para si a faculdade de colocar o seu logotipo e o de um dos seus parceiros em todos os serviços por ela efetuados.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 15 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Ao final, o envio do(s) produto(s) aos Contratantes estará condicionado ao pagamento de todas as parcelas, inclusive as vincendas.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 16 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          No caso de desfazimento unilateral do presente contratado, ou seu descumprimento, a parte que lhe der causa se obrigará ao pagamento da multa no valor de R$ ${this.formatReal(
        this.state.valores.multa
      )}, sendo que aos Contratantes a título de multas e à Contratada pelo custeio e despesas já realizadas, inclusive com pessoal.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Parágrafo Único - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                               Se a rescisão for por parte dos Contratantes e ocorrer até 30 (trinta) dias antes da entrega do contrato, incidirá multa de 50% (cinquenta por cento), do valor do contrato, acrescidas de despesas dos serviços até então realizados. (Cf. Código Civil, Lei nº 10.406, de 10.09.2002)`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Cláusula 17 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Para os efeitos próprios, inclusive para o de fixação de multas, as partes Contratantes, de comum acordo, fixam para este contrato o valor do montante da cláusula 02 (dois), retro, corrigido monetariamente.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 18 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Os Contratantes são solidariamente responsáveis pela totalidade das obrigações para si derivadas deste contrato. (Código Civil, artigo 275 e SS.)`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `Cláusula 19 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          O representante Comercial que aqui comparece em nome da Contratada assina o presente instrumento, em todas suas vias, na condição de seu mandatário, com poderes exclusivos para o preenchimento de claros e subscrição deste instrumento, em impresso próprio da Contratada.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Cláusula 20 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Recebendo o(s) produto(s), os Contratantes deverão examiná-lo(s) de imediato e, verificando algum defeito, deverão devolvê-lo(s), incontinente,` +
        ` à Contratada para o fim de correção, no prazo máximo de 3 (três) dias, contados da data da recepção, sob pena de ser considerado cumprido o contratado,` +
        ` estando perfeito e acabado. O referido prazo é improrrogável, tendo em vista as necessidades de acertos, correções ou ajustes nos produtos contratados.`,
      textOptions
    );
    //########################################################################### PAGINA 5 ################################################################################################################
    doc.addPage();
    line = 10;
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight), `§ 1º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `            A correção do produto é um poder  dever da Contratada, e é oponível aos Contratantes diante de qualquer outra exigência por eles apresentada.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 2), `§ 2º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `            O cronograma conterá previsão desse prazo para eventual correção do(s) produto(s), isentando- se, porém, a Contratada de qualquer responsabilidade na hipótese desse prazo já ter sido consumido por anteriores atrasos dos Contratantes.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `§ 3º -`);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `            Têm ajustando entre si, os Contratantes, que não será enviado à confecção dos produtos objeto do presente Contrato,  caso não tenha sido cumprido e pago  menos de 50% (cinquenta por cento) do valor contratado, haja vista o dispêndio de materiais para a confecção e elaboração dos mesmos.`,
      textOptions
    );
    doc.setFontType('bold');
    doc.text(marginLeft, (line += lineHeight * 3), `Cláusula 21 - `);
    doc.setFontType('normal');
    doc.text(
      marginLeft,
      line,
      `                          Estabelece-se o foro da comarca de Bandeirantes, Estado do Paraná, para solução de eventuais controversos entre as partes, por mais especial que outro possa ser. ` +
        `Encerramento. E, por estarem, assim, justos e contratados, após lido e achado conforme, o presente instrumento e seus anexos são assinados em todas suas vias, perante as testemunhas subscritas.`,
      textOptions
    );
    doc.text(
      marginLeft,
      (line += lineHeight * 5),
      ` ${this.state.cidade} , ${this.state.dataHjExtenso}`,
      textOptions
    );

    if (this.state.contratantes.length > 1) {
      doc.text(
        marginLeft,
        line + lineHeight * 2,
        `_____________________________`
      );
      doc.text(marginLeft + 20, line + lineHeight * 3, `Contratante`);
    }
    doc.text(
      marginLeft + 65,
      line + lineHeight * 2,
      `_____________________________`
    );
    doc.text(marginLeft + 85, line + lineHeight * 3, `Contratante`);
    doc.text(
      marginLeft + 135,
      line + lineHeight * 2,
      `_____________________________`
    );
    doc.text(marginLeft + 155, line + lineHeight * 3, `p. Contratada`);

    line += lineHeight * 5;

    doc.setFontType('bold');
    doc.text(marginLeft + 80, (line += lineHeight * 2), `TESTEMUNHAS`);
    doc.setFontType('normal');

    this.state.testemunhas.forEach((testemunha) => {
      doc.text(
        marginLeft,
        (line += lineHeight),
        `Assinatura: ......................................................................`
      );
      doc.text(marginLeft + 90, line, `Nome: ${testemunha.nome}`);
      doc.text(marginLeft + 160, line, `Tel.: ${testemunha.tel}`);
      doc.text(
        marginLeft,
        (line += lineHeight),
        `Cidade: ${testemunha.cidade}`
      );
      doc.text(marginLeft + 60, line, ` Endereço: ${testemunha.endereco}`);
      doc.text(marginLeft + 150, line, `CIC/RG: ${testemunha.rg}`);
      line += lineHeight;
    });
    //################################################################# Pagina 6 - Proposta #######################################################################################
    let propostaComValor = await this.gerarProposta(true),
      propostaSemValor = await this.gerarProposta(false);

    if (tipo === 'download') {
      let downloadLiberado = this.validarContrato();
      if (downloadLiberado === true) {
        doc.save(
          `contratoAlpha-n_${this.state.numContrato}_p_${this.state.orcamento.id}.pdf`
        );
      } else {
        toast.error(downloadLiberado);
      }
    } else if (tipo === 'upload') {
      let arquivoContrato = doc.output('blob'),
        arquivoPropostaComValor = propostaComValor.output('blob'),
        arquivoPropostaSemValor = propostaSemValor.output('blob'),
        arquivoC = new File([arquivoContrato], `Contrato.pdf`),
        arquivoP = new File([arquivoPropostaComValor], `Proposta.pdf`),
        arquivoPS = new File(
          [arquivoPropostaSemValor],
          `PropostaSemValores.pdf`
        ),
        formdata = new FormData();
      formdata.append('pdf', arquivoC);
      formdata.append('pdf', arquivoP);
      formdata.append('pdf', arquivoPS);
      formdata.append(
        'numeroContrato',
        this.state.orcamento.cliente.numContrato
      );
      await axios.post(REQUEST_URL + '/uploadContrato', formdata);
    }
    this.setState({ pdf: doc.output('datauristring') });
  }

  limparHelpBlock() {
    document.querySelectorAll('.helpBlock').forEach(function(el) {
      el.remove();
    });
  }

  adicionarSpan(mensagem) {
    let span = document.createElement('span');
    span.textContent = mensagem;
    span.classList.add('helpBlock');
    return span;
  }

  validarContrato() {
    this.limparHelpBlock();
    let contratantes = this.state.contratantes,
      turma = this.state.turma,
      testemunhas = this.state.testemunhas,
      representante = this.state.contratada,
      invalido = true;
    // Validar contratantes
    contratantes.forEach((contratante, i) => {
      let campos = Object.keys(contratante);
      campos.forEach((campo) => {
        let input = document.querySelector(`#c${i}_${campo}`);
        if (campo.indexOf('telRes') === -1 && campo.indexOf('rg') === -1) {
          if (input !== null && !input.checkValidity()) {
            invalido = `Há um problema nos dados do contratante ${i + 1}!`;
            input
              .closest('div')
              .appendChild(this.adicionarSpan(input.validationMessage));
          }
        }
      });
    });
    // Validar turma
    if (invalido) {
      let campos = Object.keys(turma);
      campos.forEach((campo) => {
        let input = document.querySelector(`#t_${campo}`);
        if (
          campo.indexOf('nome') === -1 &&
          campo.indexOf('turno') === -1 &&
          campo.indexOf('fullDate') === -1
        ) {
          if (input !== null && !input.checkValidity()) {
            invalido = 'Há um problema nos dados da turma!';
            input
              .closest('div')
              .appendChild(this.adicionarSpan(input.validationMessage));
          }
        }
        // Validação personalizada para a data de colação
        if (
          campo === 'fullDate' &&
          (turma[campo] === '' ||
            this.splitReverseJoinToEnglish(
              this.toLocalePt(new Date(turma[campo]))
            ) <= this.splitReverseJoinToEnglish(this.toLocalePt(new Date())))
        ) {
          invalido = 'Há um problema nos dados da turma!';
          input
            .closest('div')
            .appendChild(
              this.adicionarSpan(
                'A data da colação não pode menor ou igual ao dia de hoje!'
              )
            );
        }
      });
      // Validação do campo cidade, que não está no json de turma
      if (invalido) {
        let input = document.querySelector(`#t_cidade`);
        if (input !== null && !input.checkValidity()) {
          invalido = 'Há um problema nos dados da turma!';
          input
            .closest('div')
            .appendChild(this.adicionarSpan(input.validationMessage));
        }
      }
    }
    // Validar testemunhas
    if (invalido) {
      testemunhas.forEach((testemunha, i) => {
        let campos = Object.keys(testemunha);
        campos.forEach((campo) => {
          let input = document.querySelector(`#test${i}_${campo}`);
          if (campo === 'rg' || campo === 'cpf') {
            if (
              !document.querySelector(`#test${i}_rg`).checkValidity() &&
              !document.querySelector(`#test${i}_cpf`).checkValidity()
            ) {
              invalido = `É necessário informar o RG ou o CPF da testemunha ${i +
                1}!`;
              input
                .closest('div')
                .appendChild(this.adicionarSpan(input.validationMessage));
            }
          } else if (input !== null && !input.checkValidity()) {
            invalido = `Há um problema nos dados da testemunha ${i + 1}!`;
            input
              .closest('div')
              .appendChild(this.adicionarSpan(input.validationMessage));
          }
        });
      });
    }
    //Validar representante
    if (invalido) {
      let campos = Object.keys(representante);
      campos.forEach((campo) => {
        let input = document.querySelector(`#cont_${campo}`);
        if (input !== null && !input.checkValidity()) {
          invalido = 'Há um problema nos dados do representante!';
          input
            .closest('div')
            .appendChild(this.adicionarSpan(input.validationMessage));
        }
      });
    }
    return invalido;
  }

  splitReverseJoinToEnglish(data) {
    return data
      .split('/')
      .reverse()
      .join('-');
  }

  toLocalePt(data) {
    return data.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  }

  async addParcela() {
    let valores = Object.assign({}, this.state.valores),
      numParcelas = valores.parcelas.length,
      dataFormatura = new Date(this.state.turma.fullDate),
      ultimoDia = new Date(
        dataFormatura.getFullYear(),
        dataFormatura.getMonth() + 1,
        0
      ),
      diaUltima = new Date(
        new Date(valores.parcelas[valores.parcelas.length - 1].data).getTime()
      ),
      parcelaAtual = this.splitReverseJoinToEnglish(
        this.toLocalePt(new Date(diaUltima.setMonth(diaUltima.getMonth() + 1)))
      );
    if (
      numParcelas <= 11 &&
      new Date(parcelaAtual).getTime() <= ultimoDia.getTime()
    ) {
      valores.parcelas.push({ valor: '500', data: parcelaAtual });
      let precoParcela = valores.total / valores.parcelas.length;
      valores.parcelas.forEach((parcela) => {
        parcela.valor = Number(precoParcela.toFixed(2));
      });
      this.setState({ valores, botaoDownload: true });
    }
  }

  bloquearInputsParcela() {
    let inputs = document.querySelectorAll('.numero-parcela');
    if (inputs.length === 1) {
      inputs[inputs.length - 1].setAttribute('disabled', 'true');
    } else if (inputs.length > 1) {
      inputs[0].removeAttribute('disabled');
      inputs[inputs.length - 1].setAttribute('disabled', 'true');
      inputs[inputs.length - 2].removeAttribute('disabled');
    }
  }

  async removeParcela() {
    let valores = this.state.valores,
      deletou = true;
    if (valores.parcelas.length > 1) {
      if (valores.parcelas[valores.parcelas.length - 1].id !== undefined) {
        await axios
          .delete(
            `${REQUEST_URL}/contrato/parcela/${valores.parcelas[valores.parcelas.length - 1].id}`
          )
          .catch((erro) => {
            this.criarLog(
              'ERROR',
              erro,
              `Erro ao remover a parcela ${valores.parcelas.length}`
            );
            toast.error(`Erro ao remover a parcela ${valores.parcelas.length}`);
            deletou = false;
          });
      }
      if (deletou) {
        valores.parcelas.pop();
        let qtdParcelas = valores.parcelas.length,
          precoParcela = valores.total / qtdParcelas;
        valores.parcelas.forEach((parcela) => {
          parcela.valor = Number(precoParcela.toFixed(2));
        });
        this.setState({ valores, botaoDownload: true });
      }
    }
  }

  changeData(index, e) {
    let valores = this.state.valores;
    valores.parcelas[index].data = e.target.value;
    this.setState({ valores, botaoDownload: true });
  }

  validarData(el = undefined) {
    let dataFormatura = new Date(this.state.turma.fullDate),
      diaInicialLimite = this.splitReverseJoinToEnglish(
        this.toLocalePt(new Date())
      ),
      diaFinalLimite = new Date(
        dataFormatura.getFullYear(),
        dataFormatura.getMonth(),
        dataFormatura.getDate(),
        dataFormatura.getHours() + 3
      ),
      dataInserida =
        el === undefined
          ? this.state.valores.parcelas[this.state.valores.parcelas.length - 1]
              .data
          : el.target.value;
    diaFinalLimite = this.splitReverseJoinToEnglish(
      this.toLocalePt(
        new Date(diaFinalLimite.setMonth(diaFinalLimite.getMonth() - 1))
      )
    );
    if (dataInserida < diaInicialLimite) {
      toast.error('Datas retroativas não são permitidas!');
      this.setState({ problemasComDatas: true, salvarOrcamento: false });
    } else if (dataInserida > diaFinalLimite) {
      toast.error(
        'As parcelas só podem ser agendadas com até um mês antes da colação!'
      );
      this.setState({ problemasComDatas: true, salvarOrcamento: false });
    } else {
      this.setState({ problemasComDatas: false, salvarOrcamento: false });
    }
  }

  getCodCliente() {
    return this.state.orcamento.cliente.codCliente !== undefined
      ? this.state.orcamento.cliente.codCliente
      : this.state.orcamento.cliente.idCliente;
  }

  getCodVendedor() {
    return this.state.orcamento.cliente.codVendedor !== undefined
      ? this.state.orcamento.cliente.codVendedor
      : this.state.orcamento.cliente.Vendedor;
  }

  changeValorParcela(index, e) {
    let valores = this.state.valores;
    valores.parcelas[index].valor = Number(e.target.value);
    this.setState({ valores });
    let somaAteIndex = 0;
    valores.parcelas.forEach((parcela, i) => {
      if (i <= index) {
        somaAteIndex += parcela.valor;
      } else {
        let restante = valores.total - somaAteIndex;
        let qtdRestoParcelas = valores.parcelas.length - (index + 1);
        parcela.valor = (restante / qtdRestoParcelas).toFixed(2);
      }
    });
    this.setState({ valores, botaoDownload: true });
  }

  formatReal(n) {
    if (n !== undefined || n !== null) {
      return Number(
        Number(n)
          .toFixed(2)
          .toLocaleString('pt-BR')
      );
    }
  }

  async validarDocumento(documento) {
    if (documento !== true) {
      var validator = require('br-doc-validator');
      return await validator.documentValidatorProm(documento).then(
        () => {
          return true;
        },
        () => {
          return false;
        }
      );
    }
    return true;
  }

  addContratante() {
    let contratantes = this.state.contratantes,
      tipoPessoas = this.state.tipoPessoas;
    if (contratantes.length < 2) {
      contratantes.push({
        nome: '',
        cpf: '',
        rg: '',
        cnpj: '',
        endereco: '',
        telCel: '',
        telRes: '',
        bairro: '',
        email: '',
        cidade: '',
        estado: '',
        CEP: '',
      });
      tipoPessoas.push('fisica');
      this.setState({ contratantes, tipoPessoas, botaoDownload: true });
    }
  }
  addTestemunha() {
    let testemunhas = this.state.testemunhas;
    if (testemunhas.length < 2) {
      testemunhas.push({
        nome: '',
        rg: '',
        endereco: '',
        tel: '',
        cidade: '',
        cpf: '',
      });
      this.setState({ testemunhas, botaoDownload: true });
    }
  }
  removerContratante() {
    let contratantes = this.state.contratantes;
    if (contratantes.length > 1) {
      contratantes.pop();
      this.setState({ contratantes, botaoDownload: true });
    }
  }
  removerTestemunha() {
    let testemunhas = this.state.testemunhas;
    if (testemunhas.length > 1) {
      testemunhas.pop();
      this.setState({ testemunhas, botaoDownload: true });
    }
  }
  handleChangeContratantes(i, el) {
    let inputName = el.target.name,
      inputValue = el.target.value,
      contratantes = this.state.contratantes;

    contratantes[i][inputName] =
      inputName === 'email' ? inputValue.trim() : inputValue;
    this.setState({ contratantes, botaoDownload: true });
  }
  handleChangeTipoPessoas(i, e) {
    let inputValue = e.target.value,
      tipoPessoas = this.state.tipoPessoas,
      contratantes = this.state.contratantes;
    tipoPessoas[i] = inputValue;
    if (inputValue === 'fisica') {
      contratantes[i]['cnpj'] = '';
    } else {
      contratantes[i]['cpf'] = '';
      contratantes[i]['rg'] = '';
    }
    this.setState({ tipoPessoas, contratantes, botaoDownload: true });
  }
  handleChangeTestemunhas(i, el) {
    let inputName = el.target.name;
    let inputValue = el.target.value;
    let testemunhas = this.state.testemunhas;
    testemunhas[i][inputName] = inputValue;
    this.setState({ testemunhas, botaoDownload: true });
  }
  async encontrarCidade(i, el) {
    let contratantes = this.state.contratantes,
      valor = contratantes[i]['CEP'];
    if (
      valor !== undefined &&
      valor.replace('-', '').replace('_', '').length === 8
    ) {
      this.setState({ procurandoCep: true });
      valor = valor.replace('-', '').replace(' ', '');
      await axios
        .post(`${REQUEST_URL}/encontrar-cidade`, { cep: valor })
        .then((data) => {
          data = data.data;
          contratantes[i]['estado'] = data.estado;
          contratantes[i]['cidade'] = data.cidade;
          this.setState({ procurandoCep: false, contratantes });
        })
        .catch((erro) => {
          toast.error(erro);
          contratantes[i]['estado'] = '';
          contratantes[i]['cidade'] = '';
          document.querySelector(`#c${i}_estado`).removeAttribute('disabled');
          document.querySelector(`#c${i}_cidade`).removeAttribute('disabled');
          toast.info(
            'Não encontramos as informações deste CEP. Por favor, preencha manualmente!'
          );
          this.setState({ procurandoCep: false, contratantes });
        });
    }
  }
  reabrirOrcamento() {
    this.props.history.push({
      pathname: '/orcamento',
      state: {
        idOrc: this.state.proposta.numero,
        cliente: this.state.cliente,
      },
    });
  }
  handleChangeFullDate() {
    let turma = Object.assign({}, this.state.turma),
      mesExtenso = [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ];
    turma.mes = mesExtenso[new Date(turma.fullDate).getMonth() + 1];
    turma.ano = new Date(turma.fullDate).getFullYear();
    this.setState({ turma: turma, botaoDownload: true });
  }
  async finalizarOrcamento() {
    this.setState({ finalizarOrcamento: true });
    let contratoSalvo = this.state.contratoSalvo,
      downloadLiberado = this.validarContrato();
    if (downloadLiberado === true) {
      let documentoValidado1 = await this.validarDocumento(
          contratoSalvo.c1_cpf ? contratoSalvo.c1_cpf : contratoSalvo.c1_cnpj
        ),
        documentoValidado2 = await this.validarDocumento(
          contratoSalvo.c2_cpf
            ? contratoSalvo.c2_cpf
            : contratoSalvo.c2_cnpj
            ? contratoSalvo.c2_cnpj
            : true
        );
      if (documentoValidado1) {
        await axios
          .post(
            `${REQUEST_URL}/contrato_orcamento/${this.state.proposta.numero}`
          )
          .then(async (response) => {
            if (response.data.length === 0) {
              if (documentoValidado2) {
                this.downloadProposta('upload');
                await axios
                  .get(`${REQUEST_URL}/getSalesmenId/${this.getCodVendedor()}`)
                  .then((response) => {
                    axios
                      .post(`${REQUEST_URL}/orcamento/status-orcamento`, {
                        orcamento: this.state.orcamento.id,
                        status: 'Contrato Gerado',
                      })
                      .then(async () => {
                        toast.success('Contrato gerado!');
                        axios.put(
                          `${REQUEST_URL}/cliente/${this.getCodCliente()}/formatura`,
                          {
                            formatura: this.state.turma.fullDate.replace(
                              /-/g,
                              '/'
                            ),
                          }
                        );
                        this.props.updateState({
                          status: 'Contrato Gerado',
                          numero: this.state.orcamento.cliente.numContrato,
                          dataFormatura: this.state.turma.fullDate,
                          anoCorrente: this.state.ano_corrente,
                        });
                        this.props.onCloseModal(false);
                        // let whatsapp = new WhatsappServices(),
                        //   telefone = this.state.contratoSalvo.c1_telcel.replace("(", "").replace(")", "").replace(/\ /g, "").replace("-", "");
                        // whatsapp.sendMessage({celular: Number(String("55" + telefone)), nome: this.state.contratoSalvo.c1_nome, tipo: "contrato"});
                        // whatsapp.sendMessageWithAFile({celular: String("55" + telefone), mensagem: `Contrato-${this.state.orcamento.cliente.numContrato}`, link: `https://alphaplay.alphaconvites.com.br/contratosMapaVendas/Contrato-${this.state.orcamento.cliente.numContrato}.pdf`, extensao: "pdf"});
                        this.setState({ finalizarOrcamento: false });
                      })
                      .catch((erro) => {
                        this.criarLog(
                          'ERROR',
                          erro,
                          'Não foi possível atualizar o status do orçamento (download_proposta)'
                        );
                        toast.error(
                          'Não foi possível atualizar o status do orçamento (download_proposta)'
                        );
                        this.setState({ finalizarOrcamento: false });
                      });
                  })
                  .catch((erro) => {
                    this.criarLog(
                      'ERROR',
                      erro,
                      'Não foi possível pegar o código do vendedor deste orçamento'
                    );
                    toast.error(
                      'Não foi possível pegar o código do vendedor deste orçamento'
                    );
                    this.setState({ finalizarOrcamento: false });
                  });
              } else {
                toast.error(
                  `${
                    contratoSalvo.c2_cpf ? 'CPF' : 'CNPJ'
                  } do contratante 2 é inválido!`
                );
                this.setState({ finalizarOrcamento: false });
              }
            } else {
              toast.error(
                `Não é possível cadastrar um contrato com um ${
                  contratoSalvo.c1_cpf ? 'CPF' : 'CNPJ'
                } já cadastrado no sistema!`
              );
              this.setState({ finalizarOrcamento: false });
            }
          })
          .catch((erro) => {
            this.criarLog(
              'ERROR',
              erro,
              'Falha ao pesquisar o documento para validar o contrato'
            );
            toast.error(
              `Falha ao pesquisar o ${contratoSalvo.c1_cpf ? 'CPF' : 'CNPJ'}!`
            );
            this.setState({ finalizarOrcamento: false });
          });
      } else {
        toast.error(
          `${contratoSalvo.c1_cpf ? 'CPF' : 'CNPJ'} do contratante 1 inválido!`
        );
        this.setState({ finalizarOrcamento: false });
      }
    } else {
      toast.error(downloadLiberado);
      this.setState({ finalizarOrcamento: false });
    }
  }
  render() {
    return (
      <div>
        {this.state.renderizarContrato ? (
          <React.Fragment>
            <Nav tabs>
              <NavItem
                onClick={() => this.setState({ tabsControle: 'contratante' })}
              >
                <NavLink active={this.state.tabsControle === 'contratante'}>
                  Contratantes
                </NavLink>
              </NavItem>
              <NavItem
                onClick={() => this.setState({ tabsControle: 'representante' })}
              >
                <NavLink active={this.state.tabsControle === 'representante'}>
                  Representante
                </NavLink>
              </NavItem>
              <NavItem
                onClick={() => this.setState({ tabsControle: 'testemunhas' })}
              >
                <NavLink active={this.state.tabsControle === 'testemunhas'}>
                  Testemunhas
                </NavLink>
              </NavItem>
              <NavItem
                onClick={() => this.setState({ tabsControle: 'pagamento' })}
              >
                <NavLink active={this.state.tabsControle === 'pagamento'}>
                  Pagamento
                </NavLink>
              </NavItem>
              <NavItem
                onClick={() => this.setState({ tabsControle: 'modelo' })}
              >
                <NavLink active={this.state.tabsControle === 'modelo'}>
                  Modelo
                </NavLink>
              </NavItem>
            </Nav>

            <div
              name='Outras opções'
              hidden={!(this.state.tabsControle === 'modelo')}
              style={styleTabBody}
            >
              <br />
              <label>Tipo do contrato</label>
              <br />
              <label>
                <input
                  type='radio'
                  name='tipoPagto'
                  checked={this.state.tipo === 'alpha'}
                  value='alpha'
                  onClick={() => {
                    this.setState({ tipo: 'alpha', botaoDownload: true });
                  }}
                />{' '}
                Alpha Ultrapress
              </label>
              <br />
              <label>
                <input
                  type='radio'
                  name='tipoPagto'
                  checked={this.state.tipo === 'beta'}
                  value='beta'
                  onClick={() => {
                    this.setState({ tipo: 'beta', botaoDownload: true });
                  }}
                />{' '}
                Beta Imagem
              </label>
              <br />
              <div className='row'>
                <div className='col-sm-12 col-md-8'>
                  <label>Data de chegada</label>
                  <input
                    type='date'
                    className='form-control form-group'
                    value={this.state.dt_chegada}
                    onChange={(e) => {
                      this.setState({
                        dt_chegada: e.target.value,
                        botaoDownload: true,
                      });
                    }}
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>Ano corrente</label>
                  <input
                    type='text'
                    className='form-control form-group'
                    value={this.state.ano_corrente}
                    onChange={(e) => {
                      this.setState({
                        ano_corrente: e.target.value,
                        botaoDownload: true,
                      });
                    }}
                  />
                </div>
              </div>
              <label>Referência do cliente</label>
              <input
                type='text'
                className='form-control col-sm-12 col-md-6 form-group'
                value={this.state.ref_cliente}
                onChange={(e) => {
                  this.setState({
                    ref_cliente: e.target.value,
                    botaoDownload: true,
                  });
                }}
              />
            </div>
            <div
              name='contratante'
              hidden={!(this.state.tabsControle === 'contratante')}
              style={styleTabBody}
            >
              <br />
              <button
                className='btn btn-info btn-sm'
                onClick={this.removerContratante.bind(this)}
              >
                <i className='fas fa-minus'></i>
              </button>
              {' ' + this.state.contratantes.length + ' '}
              <button
                className='btn btn-info btn-sm'
                onClick={this.addContratante.bind(this)}
              >
                <i className='fas fa-plus'></i>
              </button>
              {this.state.contratantes.map((contratante, i) => (
                <div key={i}>
                  <br />
                  <h5>Contratante {i + 1}</h5>
                  <label>
                    <input
                      type='radio'
                      checked={this.state.tipoPessoas[i] !== 'juridica'}
                      value='fisica'
                      name={'tipoPessoa' + i}
                      onClick={this.handleChangeTipoPessoas.bind(this, i)}
                    />{' '}
                    Pessoa física
                  </label>
                  <label style={{ marginLeft: 5 }}>
                    <input
                      type='radio'
                      checked={this.state.tipoPessoas[i] === 'juridica'}
                      value='juridica'
                      name={'tipoPessoa' + i}
                      onClick={this.handleChangeTipoPessoas.bind(this, i)}
                    />{' '}
                    Pessoa jurídica
                  </label>
                  <div className='row form-group'>
                    <div className='col-sm-12 col-md-6'>
                      <label>* Nome</label>
                      <input
                        type='text'
                        id={`c${i + 1}_nome`}
                        name='nome'
                        className='form-control'
                        value={contratante.nome}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-6'>
                      <label>* E-mail</label>
                      <input
                        type='email'
                        id={`c${i + 1}_email`}
                        name='email'
                        className='form-control'
                        value={contratante.email}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                  </div>
                  <div className='row form-group'>
                    {this.state.tipoPessoas[i] === 'juridica' ? (
                      <div className='col-sm-12 col-md-6'>
                        <label>* CNPJ</label>
                        <InputMask
                          name='cnpj'
                          id={`c${i}_cnpj`}
                          className='form-control'
                          mask='99.999.999/9999-99'
                          value={contratante.cnpj}
                          onChange={this.handleChangeContratantes.bind(this, i)}
                          required
                        />
                      </div>
                    ) : (
                      <div className='col-sm-12 col-md-6'>
                        <div className='row'>
                          <div className='col-sm-12 col-md-6'>
                            <label>* CPF</label>
                            <InputMask
                              name='cpf'
                              id={`c${i}_cpf`}
                              className='form-control'
                              mask='999.999.999-99'
                              value={contratante.cpf}
                              onChange={this.handleChangeContratantes.bind(
                                this,
                                i
                              )}
                              required
                            />
                          </div>
                          <div className='col-sm-12 col-md-6'>
                            <label>RG</label>
                            <InputMask
                              name='rg'
                              id={`c${i}_rg`}
                              className='form-control'
                              mask='99.999.999-9'
                              value={contratante.rg}
                              onChange={this.handleChangeContratantes.bind(
                                this,
                                i
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='col-sm-12 col-md-3'>
                      <label>Telefone Residencial</label>
                      <InputMask
                        name='telRes'
                        className='form-control'
                        mask='(99) 9999 9999'
                        value={contratante.telRes}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                      />
                    </div>
                    <div className='col-sm-12 col-md-3'>
                      <label>* Telefone Celular</label>
                      <InputMask
                        name='telCel'
                        id={`c${i}_telCel`}
                        className='form-control'
                        mask='(99) 9 9999 9999'
                        value={contratante.telCel}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-sm-12 col-md-6'>
                      <label>* Endereço</label>
                      <input
                        type='text'
                        name='endereco'
                        id={`c${i}_endereco`}
                        className='form-control'
                        value={contratante.endereco}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-9 col-md-4'>
                      <label>* Bairro</label>
                      <input
                        type='text'
                        name='bairro'
                        id={`c${i}_bairro`}
                        className='form-control'
                        value={contratante.bairro}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-3 col-md-2'>
                      <label>* Número</label>
                      <input
                        type='number'
                        name='numero'
                        id={`c${i}_numero`}
                        className='form-control'
                        min='0'
                        value={contratante.numero}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        required
                      />
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-sm-12 col-md-3'>
                      <label>* CEP</label>
                      <InputGroup>
                        <InputMask
                          name='CEP'
                          id={`c${i}_CEP`}
                          className='form-control'
                          mask='99999-999'
                          value={contratante.CEP}
                          onChange={this.handleChangeContratantes.bind(this, i)}
                          required
                        />
                        {!this.state.procurandoCep ? (
                          <InputGroupAddon addonType='append'>
                            <Button
                              onClick={this.encontrarCidade.bind(this, i)}
                            >
                              Ok
                            </Button>
                          </InputGroupAddon>
                        ) : (
                          <InputGroupAddon addonType='append'>
                            <Button disabled>
                              <i className='fa fa-spinner fa-pulse'></i>
                            </Button>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label>* Estado</label>
                      <input
                        type='text'
                        name='estado'
                        className='form-control'
                        value={contratante.estado}
                        id={`c${i}_estado`}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        disabled
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-5'>
                      <label>* Cidade</label>
                      <input
                        type='text'
                        name='cidade'
                        className='form-control'
                        value={contratante.cidade}
                        id={`c${i}_cidade`}
                        onChange={this.handleChangeContratantes.bind(this, i)}
                        disabled
                        required
                      />
                    </div>
                  </div>
                </div>
              ))}
              <br />
              <h5>Turma:</h5>
              <div className='row form-group'>
                <div className='col-sm-12 col-md-4'>
                  <label>* Cidade do contrato</label>
                  <input
                    className='form-control'
                    id='t_cidade'
                    value={this.state.cidade}
                    onChange={(e) => {
                      this.setState({
                        cidade: e.target.value,
                        botaoDownload: true,
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>* Curso</label>
                  <input
                    className='form-control'
                    id='t_curso'
                    value={this.state.turma.curso}
                    onChange={(e) => {
                      this.setState({
                        turma: { ...this.state.turma, curso: e.target.value },
                        botaoDownload: true,
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>* Universidade</label>
                  <input
                    className='form-control'
                    id='t_faculdade'
                    value={this.state.turma.faculdade}
                    onChange={(e) => {
                      this.setState({
                        turma: {
                          ...this.state.turma,
                          faculdade: e.target.value,
                        },
                        botaoDownload: true,
                      });
                    }}
                    required
                  />
                </div>
              </div>
              <div className='row form-group'>
                <div className='col-sm-12 col-md-4'>
                  <label>* Estado</label>
                  <input
                    className='form-control'
                    id='t_estado'
                    value={this.state.turma.estado}
                    onChange={(e) => {
                      this.setState({
                        turma: { ...this.state.turma, estado: e.target.value },
                        botaoDownload: true,
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>* Data da Colação</label>
                  <input
                    type='date'
                    className='form-control'
                    id='t_fullDate'
                    value={this.state.turma.fullDate}
                    onChange={(e) => {
                      this.setState(
                        {
                          turma: {
                            ...this.state.turma,
                            fullDate: e.target.value,
                          },
                          botaoDownload: true,
                        },
                        () => {
                          this.handleChangeFullDate();
                        }
                      );
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div
              name='Pagamento'
              hidden={!(this.state.tabsControle === 'pagamento')}
              style={styleTabBody}
            >
              <div className='row form-group'>
                <div className='col-sm-12 col-md-10'>
                  {!this.state.individual ? (
                    <div>
                      <div className='row form-group'>
                        <div className='col-sm-12'>
                          <h5>Informações do Contrato</h5>
                          <label>Valor total</label>:{' '}
                          <strong>R${this.state.valores.total}</strong>
                          <br />
                          <label>Data da colação</label>:{' '}
                          <strong>
                            {this.state.turma.fullDate
                              .split('-')
                              .reverse()
                              .join('/')}
                          </strong>
                          <br />
                          <small style={{ fontWeight: 600, color: '#DC3545' }}>
                            Atenção:
                            <ol>
                              <li>
                                O sistema só aceitará parcelas agendadas com até
                                um mês antes da data de colação!
                              </li>
                              <li>
                                Não é possível agendar uma parcela com data
                                retroativa!
                              </li>
                              <li>
                                É possível agendar até 12 parcelas, desde que as
                                regras acima sejam cumpridas.
                              </li>
                            </ol>
                          </small>
                        </div>
                      </div>
                      <div
                        className='row'
                        style={{ margin: '0 15px', width: 'calc(100% - 30px)' }}
                      >
                        <div
                          className='col-sm-12'
                          style={{
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <label style={{ backgroundColor: 'white' }}>
                            Número de Parcelas
                          </label>
                          <div
                            style={{
                              backgroundColor: 'white',
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <button
                              className='btn btn-info btn-sm'
                              onClick={this.removeParcela.bind(this)}
                            >
                              <i className='fas fa-minus'></i>
                            </button>
                            <span style={{ width: 30, textAlign: 'center' }}>
                              {this.state.valores.parcelas.length}
                            </span>
                            <button
                              className='btn btn-info btn-sm'
                              onClick={this.addParcela.bind(this)}
                            >
                              <i className='fas fa-plus'></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          border: '1px solid #e9ecef',
                          padding: 15,
                          borderRadius: '.25rem',
                          marginTop: '-20px',
                        }}
                      >
                        {this.state.valores.parcelas.map((parcela, i) => (
                          <div key={i} className='row form-group'>
                            <div className='col-sm-6'>
                              <small>
                                <label>Parcela {i + 1}</label>
                              </small>
                              <input
                                type='date'
                                className='form-control'
                                onChange={this.changeData.bind(this, i)}
                                value={parcela.data}
                                onBlur={this.validarData.bind(this)}
                              />
                            </div>
                            <div className='col-sm-6'>
                              <small>
                                <label style={{ color: 'white' }}>.</label>
                              </small>
                              <input
                                type='number'
                                className='numero-parcela form-control'
                                value={parcela.valor}
                                onChange={this.changeValorParcela.bind(this, i)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <br />
                      Pagamento individual selecionado
                      <br /> Valor total: {this.state.valores.total}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              name='Representante'
              hidden={!(this.state.tabsControle === 'representante')}
              style={styleTabBody}
            >
              <div className='row form-group'>
                <div className='col-sm-12 col-md-4'>
                  <label>* Representante</label>
                  <input
                    disabled
                    className='form-control'
                    id='cont_representante'
                    value={this.state.contratada.representante}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          representante: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>* Carteira</label>
                  <input
                    className='form-control'
                    id='cont_carteira'
                    value={this.state.contratada.carteira}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          carteira: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-4'>
                  <label>* Estado Carteira</label>
                  <input
                    className='form-control'
                    id='cont_estadoDaCarteira'
                    value={this.state.contratada.estadoDaCarteira}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          estadoDaCarteira: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
              </div>
              <div className='row form-group'>
                <div className='col-sm-12 col-md-3'>
                  <label>* Rua</label>
                  <input
                    className='form-control'
                    id='cont_rua'
                    value={this.state.contratada.rua}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          rua: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-3'>
                  <label>* Cidade</label>
                  <input
                    className='form-control'
                    id='cont_cidade'
                    value={this.state.contratada.cidade}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          cidade: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-3'>
                  <label>* Estado</label>
                  <input
                    className='form-control'
                    id='cont_estado'
                    value={this.state.contratada.estado}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          estado: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
                <div className='col-sm-12 col-md-3'>
                  <label>* Telefone</label>
                  <InputMask
                    id='cont_telefone'
                    className='form-control'
                    mask='(99) 9 9999 9999'
                    value={this.state.contratada.telefone}
                    onChange={(e) => {
                      this.setState({
                        contratada: {
                          ...this.state.contratada,
                          telefone: e.target.value,
                        },
                      });
                    }}
                    required
                  />
                </div>
              </div>
            </div>
            <div
              name='Testemunhas'
              hidden={!(this.state.tabsControle === 'testemunhas')}
              style={styleTabBody}
            >
              <br />
              <button
                className='btn btn-info btn-sm'
                onClick={this.removerTestemunha.bind(this)}
              >
                <i className='fas fa-minus'></i>
              </button>
              {' ' + this.state.testemunhas.length + ' '}
              <button
                className='btn btn-info btn-sm'
                onClick={this.addTestemunha.bind(this)}
              >
                <i className='fas fa-plus'></i>
              </button>
              {this.state.testemunhas.map((testemunha, i) => (
                <div key={i}>
                  <br />
                  <h5>Testemunha {i + 1}</h5>
                  <div className='row form-group'>
                    <div className='col-sm-12 col-md-4'>
                      <label>* Nome</label>
                      <input
                        name='nome'
                        className='form-control'
                        id={`test${i}_nome`}
                        value={testemunha.nome}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label>* Endereço</label>
                      <input
                        name='endereco'
                        className='form-control'
                        id={`test${i}_endereco`}
                        value={testemunha.endereco}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label>* Tel:</label>
                      <input
                        name='tel'
                        className='form-control'
                        id={`test${i}_tel`}
                        value={testemunha.tel}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                  </div>
                  <div className='row form-group'>
                    <div className='col-sm-12 col-md-4'>
                      <label>* Cidade</label>
                      <input
                        name='cidade'
                        className='form-control'
                        id={`test${i}_cidade`}
                        value={testemunha.cidade}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label>RG:</label>
                      <InputMask
                        name='rg'
                        className='form-control'
                        id={`test${i}_rg`}
                        mask='99.999.999-9'
                        value={testemunha.rg}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                    <div className='col-sm-12 col-md-4'>
                      <label>CPF:</label>
                      <InputMask
                        name='cpf'
                        className='form-control'
                        id={`test${i}_cpf`}
                        mask='999.999.999-99'
                        value={testemunha.cpf}
                        onChange={this.handleChangeTestemunhas.bind(this, i)}
                        required
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <br />
            <br />
            <div>
              <button
                className='btn btn-success'
                onClick={this.salvarContrato.bind(this)}
                disabled={
                  this.state.finalizarOrcamento || this.state.salvarOrcamento
                }
              >
                {this.state.salvarOrcamento ? (
                  <span>
                    <i className='fa fa-spinner fa-pulse'></i> Salvando{' '}
                  </span>
                ) : (
                  <span>
                    <i className='fas fa-save'></i> Salvar{' '}
                  </span>
                )}
              </button>{' '}
              <button
                className='btn btn-success'
                onClick={this.downloadProposta.bind(this, 'download')}
                disabled={
                  this.state.botaoDownload ||
                  this.state.finalizarOrcamento ||
                  this.state.salvarOrcamento
                }
              >
                <i
                  className={
                    this.state.gerarDownload
                      ? 'fa fa-spinner fa-pulse'
                      : 'fas fa-download'
                  }
                ></i>{' '}
                Download
              </button>{' '}
              <button
                className='btn btn-danger'
                style={{ float: 'right' }}
                onClick={this.finalizarOrcamento.bind(this)}
                disabled={
                  this.state.botaoDownload ||
                  this.state.finalizarOrcamento ||
                  this.state.salvarOrcamento
                }
              >
                <i
                  className={
                    this.state.finalizarOrcamento
                      ? 'fa fa-spinner fa-pulse'
                      : 'fas fa-exclamation-circle'
                  }
                ></i>{' '}
                Finalizar orçamento
              </button>
            </div>
          </React.Fragment>
        ) : (
          <h4>Não existem contatos cadastrados para este cliente.</h4>
        )}
      </div>
    );
  }
}
const styleTabBody = {
  padding: '5px',
  borderLeft: '1px solid #dee2e6',
  borderRight: '1px solid #dee2e6',
  borderBottom: '1px solid #dee2e6',
};
