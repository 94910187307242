import axios from 'axios';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import NavBar from '../components/NavBar';

const Produtos = (props) => {
  const storagedUser = sessionStorage.getItem('usuarioLogado');
  const usuarioLogado = storagedUser ? JSON.parse(storagedUser)[0] : undefined;
  const servicos = [
    'CAPA DURA',
    'MIOLO',
    'SIMPLES',
    'ENCARTE',
    'EMBALAGEM',
    'OUTROS',
  ];

  function validateAccess() {
    if (usuarioLogado) {
      if (
        usuarioLogado.Funcao === 'VENDEDOR' ||
        usuarioLogado.Nivel === 'RESTRITO'
      ) {
        props.history.push('/mapa');
      }
    }
  }

  useEffect(() => {
    axios.defaults.headers.common = {
      Authorization: sessionStorage.getItem('token'),
    };

    validateAccess();
  }, {});

  if (window.location.href.includes('mapavendas')) {
    return (
      <div
        style={{
          backgroundColor: '#f5f4f9',
          paddingBottom: 30,
          position: 'relative',
          minHeight: '100vh',
        }}
      >
        <ToastContainer
          position='bottom-left'
          autoClose={10000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        <NavBar nome={usuarioLogado.Nome} funcao={usuarioLogado.Funcao} />
        <Row className='conteudo' style={{ margin: '0px 0px 0px 0px' }}>
          <Col id='container' style={{ marginTop: 20 }}>
            <Card
              style={{
                marginTop: 20,
                boxShadow:
                  '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
                borderRadius: '.375rem',
              }}
            >
              <CardHeader
                style={{
                  borderBottom: 'none',
                  backgroundColor: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: '.375rem',
                }}
              >
                Tela em desenvolvimento
              </CardHeader>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: '#f5f4f9',
        paddingBottom: 30,
        position: 'relative',
        minHeight: '100vh',
      }}
    >
      <ToastContainer
        position='bottom-left'
        autoClose={10000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
      />
      <NavBar nome={usuarioLogado.Nome} funcao={usuarioLogado.Funcao} />
      <Row className='conteudo' style={{ margin: '0px 0px 0px 0px' }}>
        <Col id='container' style={{ marginTop: 20 }}>
          <Card
            style={{
              marginTop: 20,
              boxShadow:
                '0.5rem 0.5rem 2rem 0 rgb(8 15 41 / 8%), 0 0 1px 0 rgb(8 15 41 / 8%)',
              borderRadius: '.375rem',
            }}
          >
            <CardHeader
              style={{
                borderBottom: 'none',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderRadius: '.375rem',
              }}
            >
              Gerenciamento de Produtos e Acabamentos
            </CardHeader>
            <CardBody>
              <p style={{ fontSize: 12 }}>Filtros</p>
              <Row>
                <FormGroup>
                  <Label for='servico'>
                    <strong>Selecione o serviço:</strong>
                  </Label>
                  <select className='form-control' name='servico' id='servico'>
                    {servicos.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Produtos;
