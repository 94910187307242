import Flipcard from '@kennethormandy/react-flipcard';
import '@kennethormandy/react-flipcard/dist/Flipcard.css';
import axios from 'axios';
import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Redirect } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { toast } from 'react-toastify';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import NavBar from '../components/NavBar';
import { REQUEST_URL } from '../config/urls.js';

class Mapa extends React.Component {
  constructor(props) {
    super(props);
    document.title = 'Mapa de Vendas | Alpha Ultrapress';
    this.state = {
      region: '',
      code: '',
      cidades: [],
      cidadesSelecionadas: [],
      instituicoes: [],
      instituicoesFiltradas: [],
      instituicoesSelecionadas: [],
      cidadesFiltradas: [],
      inputBusca: '',
      inputBuscaCidade: '',
      cursos: [],
      cursosFiltrados: [],
      cursosSelecionados: [],
      vendedores: [],
      flipped: false,
      valoresTabelaExportar: [],
      valoresTabelaCidadeVendedor: [],
      modalApontar: false,
      responsavel: '',
      usuarioLogado: {},
      vendedor: {},
      metaRealizada: 0,
      metaFaltante: 0,
      parar: true,
      qtdMetaRealizada: 0,
      instituicoesNoEstado: [],
      carregando: false,
      showModalAlterarQtdMetaRealizada: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  async componentWillMount() {
    axios.defaults.headers.common = {
      Authorization: sessionStorage.getItem('token'),
    };
    await axios
      .get(REQUEST_URL + '/meta/realizada')
      .then((data) => {
        this.setState({ metaRealizada: data.data[0].meta_atingida }, () => {
          this.atualizarMetaFaltante();
        });
      })
      .catch(() => {
        toast.error('Não foi possível atualizar as informações da meta!');
      });
  }
  componentDidUpdate() {
    if (this.state.parar) {
      this.setState({ parar: false });
      this.atualizarMetaFaltante();
    }
  }
  atualizarMetaFaltante() {
    this.setState({ metaFaltante: 90 - this.state.metaRealizada });
  }
  limparSelecao() {
    this.setState({ cidadesSelecionadas: [] }, () => {
      this.exportarCidadesSelecionadas();
    });
  }
  limparSelecaoInst() {
    this.setState({ instituicoesSelecionadas: [] });
  }
  limparSelecaoCurso() {
    this.setState({ cursosSelecionados: [] });
  }
  selectCidade(cidade) {
    let existe = this.state.cidadesSelecionadas.filter((c) => {
        return c.codigo === cidade.codigo;
      }),
      cidadesSelecionadas = this.state.cidadesSelecionadas;
    if (existe.length > 0) {
      cidadesSelecionadas = this.state.cidadesSelecionadas.filter((c) => {
        return c.codigo !== cidade.codigo;
      });
      this.setState({ cidadesSelecionadas }, () => {
        this.exportarCidadesSelecionadas();
      });
    } else {
      cidadesSelecionadas.push(cidade);
      this.setState({ cidadesSelecionadas }, () => {
        this.exportarCidadesSelecionadas();
      });
    }
    let cidades = cidadesSelecionadas.map((cidade) => {
      return cidade.codigo + '';
    });
    if (cidades.length > 0) {
      axios
        .post(`${REQUEST_URL}/cidades/insts`, { cidades: cidades })
        .then((response) => {
          let instsDasCidades = response.data,
            insts = this.state.instituicoes,
            instituicoesFiltradas = [];
          insts.forEach((inst) => {
            instsDasCidades.forEach((instDasCidades) => {
              if (inst.cod_ies == instDasCidades.cod_inst) {
                instituicoesFiltradas.push(inst);
              }
            });
          });
          this.setState({ instituicoesFiltradas });
        });
    } else {
      this.setState({ instituicoesFiltradas: this.state.instituicoesNoEstado });
    }
  }
  selectInst(inst) {
    // console.log(inst);
    // console.log(this.state.instituicoesSelecionadas);
    let existe = [];
    existe = this.state.instituicoesSelecionadas.filter((i) => {
      return i.cod_ies === inst.cod_ies;
    });
    let instituicoesSelecionadas = this.state.instituicoesSelecionadas;
    if (existe.length > 0) {
      // console.log("existe",existe);
      instituicoesSelecionadas = instituicoesSelecionadas.filter((i) => {
        return i.cod_ies !== inst.cod_ies;
      });
      this.setState({ instituicoesSelecionadas });
    } else {
      // console.log("não existe",existe);
      instituicoesSelecionadas.push(inst);
      this.setState({ instituicoesSelecionadas });
    }
    let insts = instituicoesSelecionadas.map((inst) => {
      return inst.cod_ies + '';
    });
    // console.log(insts);
    if (insts.length > 0) {
      axios
        .post(REQUEST_URL + '/insts/cidades', { instituicoes: insts })
        .then((response) => {
          // console.log(response.data);
          let cidadeDasInsts = response.data;
          let cidades = this.state.cidades;
          let cidadesFiltradas = [];
          cidades.map((cidade) => {
            cidadeDasInsts.map((cidadesInsts) => {
              //console.log(cidade, cidadesInsts)
              if (cidade.codigo == cidadesInsts.cod_cidade) {
                cidadesFiltradas.push(cidade);
              }
            });
          });
          this.setState({ cidadesFiltradas });
        });
    } else {
      this.setState({ cidadesFiltradas: this.state.cidades });
    }
  }
  selectCurso(curso) {
    // console.log(curso);
    // console.log(this.state.cursosSelecionados);
    let existe = [];
    existe = this.state.cursosSelecionados.filter((c) => {
      return c.idcurso === curso.idcurso;
    });

    if (existe.length > 0) {
      // console.log("existe",existe);
      this.setState({
        cursosSelecionados: this.state.cursosSelecionados.filter((c) => {
          return c.idcurso !== curso.idcurso;
        }),
      });
    } else {
      // console.log("não existe",existe);
      let cursosSelecionados = this.state.cursosSelecionados;
      cursosSelecionados.push(curso);
      this.setState({ cursosSelecionados });
    }
  }
  proximo() {
    let cursos = [];
    this.state.cursosSelecionados.map((c) => [cursos.push(c.idcurso)]);
    let instituicoes = [];
    this.state.instituicoesSelecionadas.map((i) => [
      instituicoes.push(i.cod_ies),
    ]);
    let cidades = [];
    this.state.cidadesSelecionadas.map((c) => {
      cidades.push(c.codigo);
      return 1;
    });
    // console.log(cidades);
    this.props.history.push({
      pathname: '/faculdades',
      state: {
        cidades: cidades,
        cidadesObj: this.state.cidadesSelecionadas,
        instituicoes: instituicoes,
        cursos: cursos,
        estado: this.state.code.toUpperCase(),
        vendedor: this.state.responsavel.value,
        vendedorNome: this.state.responsavel.label,
        somenteCidadesVinculadas: this.refs.cidadesVinculadas
          ? this.refs.cidadesVinculadas.checked
          : false,
        somenteCursosFavoritados: this.refs.cursosFavoritos.checked,
        somenteCidadesSemVinculo: this.refs.cidadesSemVinculo
          ? this.refs.cidadesSemVinculo.checked
          : false,
      },
    });
  }
  gotoKanban() {
    let cursos = [];
    this.state.cursosSelecionados.map((c) => [cursos.push(c.idcurso)]);
    let instituicoes = [];
    this.state.instituicoesSelecionadas.map((i) => [
      instituicoes.push(i.cod_ies),
    ]);
    let cidades = [];
    this.state.cidadesSelecionadas.map((c) => {
      cidades.push(c.codigo);
      return 1;
    });
    // console.log(cidades);
    this.props.history.push({
      pathname: '/historico',
      state: {
        cidades: cidades,
        cidadesObj: this.state.cidadesSelecionadas,
        instituicoes: instituicoes,
        cursos: cursos,
        estado: this.state.code.toUpperCase(),
        vendedor: this.state.responsavel.value,
        vendedorNome: this.state.responsavel.label,
        somenteCidadesVinculadas: this.refs.cidadesVinculadas
          ? this.refs.cidadesVinculadas.checked
          : false,
        somenteCursosFavoritados: this.refs.cursosFavoritos.checked,
      },
    });
  }
  filtrarInstituicoes(palavra) {
    if (palavra === '')
      this.setState({ instituicoesFiltradas: this.state.instituicoes });
    else
      this.setState({
        instituicoesFiltradas: this.state.instituicoes.filter((i) => {
          return (
            i.nomeintituicao.toUpperCase().includes(palavra.toUpperCase()) ||
            i.sigla.toUpperCase().includes(palavra.toUpperCase())
          );
        }),
      });
  }
  filtrarCursos(palavra) {
    if (palavra === '') this.setState({ cursosFiltrados: this.state.cursos });
    else
      this.setState({
        cursosFiltrados: this.state.cursos.filter((c) => {
          return c.nomecurso.toUpperCase().includes(palavra.toUpperCase());
        }),
      });
  }
  filtrarCidades(palavra) {
    if (palavra === '') this.setState({ cidadesFiltrados: this.state.cidades });
    else
      this.setState({
        cidadesFiltradas: this.state.cidades.filter((c) => {
          return c.cidade.toUpperCase().includes(palavra.toUpperCase());
        }),
      });
  }
  handleBuscaInput(event) {
    let palavra = event.target.value;
    this.setState({ inputBusca: palavra });
    this.filtrarInstituicoes(palavra);
    this.filtrarCursos(palavra);
  }
  handleBuscaInputCidades(event) {
    let palavra = event.target.value;
    // console.log( palavra);
    this.setState({ inputBuscaCidade: palavra });
    this.filtrarCidades(palavra);
  }
  handleBuscaInputCurso(event) {
    let palavra = event.target.value;
    this.setState({ inputBusca: palavra });
    //this.filtrarInstituicoes(palavra);
    this.filtrarCursos(palavra);
  }
  componentDidMount() {
    //console.log("logado=",sessionStorage.getItem('usuarioLogado'));
    if (sessionStorage.getItem('usuarioLogado')) {
      this.setState(
        {
          usuarioLogado: JSON.parse(sessionStorage.getItem('usuarioLogado'))[0],
        },
        () => {
          // console.log(this.state.usuarioLogado);
          if (this.state.usuarioLogado.Funcao === 'VENDEDOR') {
            this.setState({
              responsavel: {
                value: this.state.usuarioLogado.idUsuario,
                label: this.state.usuarioLogado.Nome,
              },
            });
          }
        }
      );
    }
    let self = this;
    window.initMap(function(element, code, region) {
      //  console.log(region);
      //  console.log(code);
      if (code === self.state.code) {
        self.setState({ code: '', region: '' });
        // console.log(self.state.code);
      } else {
        // console.log(self.state.code);
        self.setState({ region, code, carregando: true });
        axios
          .post(REQUEST_URL + '/cidades', { estado_cod: code })
          .then((response) => {
            // console.log(response.data);
            self.setState({
              cidades: response.data,
              cidadesFiltradas: response.data,
              cidadesSelecionadas: [],
            });
            axios
              .post(REQUEST_URL + '/estado/insts', { estado: code })
              .then((response2) => {
                // console.log(response2.data);
                let instsDoEstado = response2.data;
                let insts = self.state.instituicoes;
                let instituicoesFiltradas = [];
                insts.map((inst) => {
                  instsDoEstado.map((instDoEstado) => {
                    if (inst.cod_ies == instDoEstado.cod_ies) {
                      instituicoesFiltradas.push(inst);
                    }
                  });
                });
                self.setState({
                  instituicoesFiltradas,
                  instituicoesNoEstado: instituicoesFiltradas,
                  instituicoesSelecionadas: [],
                  carregando: false,
                });
              });
          });
      }
    });
    if (
      this.refs.divApp !== undefined &&
      this.refs.divApp.getBoundingClientRect().width > 960
    ) {
      axios.get(REQUEST_URL + '/instituicoes').then((response2) => {
        self.setState({
          instituicoesFiltradas: response2.data,
          instituicoes: response2.data,
          instituicoesSelecionadas: [],
        });
      });
    }
    axios.get(REQUEST_URL + '/cursos').then((response2) => {
      //console.log(response2.data);
      self.setState({
        cursosFiltrados: response2.data,
        cursos: response2.data,
        cursosSelecionados: [],
      });
    });
    axios.get(REQUEST_URL + '/vendedores').then((response) => {
      //console.log(response.data);
      let vendedores = [];
      vendedores.push({ value: '', label: 'Nenhum' });
      response.data.map((v) => {
        let o = {
          value: v.idUsuario,
          label: v.Nome,
        };
        vendedores.push(o);
      });
      self.setState({ vendedores: vendedores });
    });
  }
  toggleModal() {
    this.setState({
      modalApontar: !this.state.modalApontar,
    });
  }
  apontarVendedor() {
    // console.log(this.state.cidadesSelecionadas);
    // console.log(this.selectV.value);
    // console.log(this.refs.vendedorsecundario.checked);

    let post = {
      cidades: this.state.cidadesSelecionadas,
      instituicoes: this.state.instituicoesSelecionadas,
      idVendedor: this.selectV.value ? this.selectV.value : null,
    };

    axios.post(REQUEST_URL + '/vendedor/apontar', post).then((response) => {
      // console.log(response.data);
      this.setState({ mensagem: 'Apontamento Realizado!' });
      setTimeout(
        function() {
          this.setState({ mensagem: '' });
        }.bind(this),
        3000
      );
      axios
        .post(REQUEST_URL + '/cidades', { estado_cod: this.state.code })
        .then((response) => {
          // console.log(response.data);
          this.setState({
            cidades: response.data,
            cidadesFiltradas: response.data,
            cidadesSelecionadas: [],
          });
        });
    });
  }
  exportarCidadesSelecionadas() {
    let data = this.state.cidadesSelecionadas,
      values = [];
    data.forEach((item) => {
      let tdVendedores = '';
      if (item.vendedores.length > 0) {
        item.vendedores.forEach((vendedor) => {
          tdVendedores += `${vendedor.nome}, `;
        });
        tdVendedores = tdVendedores.substring(0, tdVendedores.length - 2);
      }
      values.push({
        cidade: item.cidade,
        vendedores: tdVendedores === '' ? 'Nenhum vendedor' : tdVendedores,
      });
    });
    this.setState({ valoresTabelaExportar: values });
  }
  handleChangeVendedor(responsavel) {
    this.setState({ responsavel });
    if (responsavel) {
      axios
        .post(`${REQUEST_URL}/vendedor/cidades`, { nome: responsavel.label })
        .then((response) => {
          this.setState({ valoresTabelaCidadeVendedor: response.data });
        })
        .catch((erro) => {
          console.log(erro);
        });
    }
  }
  handleAcharInstituicoes() {
    axios
      .get(REQUEST_URL + `/instituicoes/${this.state.inputBusca}`)
      .then((response2) => {
        this.setState({
          instituicoesFiltradas: response2.data,
          instituicoes: response2.data,
          instituicoesSelecionadas: [],
        });
      });
  }
  logout() {
    sessionStorage.setItem('usuarioLogado', '');
    //window.location.href='/login'
  }
  async salvarMetaRealizada(e) {
    e.preventDefault();
    this.setState({ carregando: true });
    await axios.put(REQUEST_URL + '/meta/realizada', {
      realizada: this.state.qtdMetaRealizada,
    });
    this.setState({
      metaRealizada: this.state.qtdMetaRealizada,
      showModalAlterarQtdMetaRealizada: false,
      carregando: false,
      parar: true,
    });
  }
  render() {
    if (!sessionStorage.getItem('usuarioLogado')) {
      return <Redirect to='/login' />;
    } else if (sessionStorage.getItem('usuarioLogado')) {
      let usuario_logado = JSON.parse(
        sessionStorage.getItem('usuarioLogado')
      )[0];
      if (usuario_logado.contadeparceiro == 1) {
        return <Redirect to='/select_orcamento' />;
      }
    }
    return (
      <div ref='divApp' style={{ height: '100%' }}>
        <NavBar
          nome={this.state.usuarioLogado.Nome}
          funcao={this.state.usuarioLogado.Funcao}
        />
        <div>
          <Row style={{ margin: '20px 0px 0px 0px' }} id='container-inicio'>
            <Col
              md='3'
              sm='12'
              xs='12'
              style={{ padding: '0px 10px 0px 10px' }}
              id='box-inst'
            >
              <Card
                style={{
                  marginBottom: 10,
                  boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
                }}
              >
                <CardBody>
                  <CardTitle>Representante:</CardTitle>
                  {this.state.usuarioLogado.Funcao !== 'VENDEDOR' ? (
                    <Label for='selectVendedor'>
                      <strong>Selecione o representante:</strong>
                    </Label>
                  ) : null}
                  <FormGroup>
                    {this.state.usuarioLogado.Funcao === 'VENDEDOR' ? (
                      <Select
                        options={[
                          {
                            value: this.state.usuarioLogado.idUsuario,
                            label: this.state.usuarioLogado.Nome,
                          },
                        ]}
                        disabled
                        value={this.state.responsavel}
                      />
                    ) : (
                      <Select
                        value={this.state.responsavel}
                        onChange={this.handleChangeVendedor.bind(this)}
                        options={this.state.vendedores}
                      />
                    )}
                  </FormGroup>
                </CardBody>
              </Card>
              <Flipcard flipped={this.state.flipped} style={{ height: 650 }}>
                <Card>
                  <CardBody>
                    <CardTitle>
                      Instituições
                      <Button
                        color='info'
                        outline
                        className='float-right'
                        onClick={(e) =>
                          this.setState({ flipped: !this.state.flipped })
                        }
                      >
                        <i className='fas fa-graduation-cap'></i>
                      </Button>
                    </CardTitle>
                    <div
                      style={{ marginTop: 20, marginBottom: 10 }}
                      className='input-group'
                    >
                      <input
                        value={this.state.inputBusca}
                        onChange={this.handleBuscaInput.bind(this)}
                        type='text'
                        className='form-control'
                      />
                      <div className='input-group-append'>
                        <button
                          className='btn btn-outline-secondary'
                          type='button'
                          onClick={this.handleAcharInstituicoes.bind(this)}
                        >
                          <i className='fa fa-search'></i>
                        </button>
                      </div>
                    </div>
                    {this.state.carregando ? (
                      <i className='fa fa-spin fa-spinner float-right'></i>
                    ) : null}
                    <p style={{ padding: '5px 0px 5px 0px' }}>
                      {this.state.instituicoesFiltradas.length}
                      &nbsp;instituições encontradas
                    </p>

                    <ul
                      style={{
                        maxHeight: 350,
                        overflow: 'auto',
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                        cursor: 'pointer',
                      }}
                    >
                      {this.state.instituicoesFiltradas.map((inst, i) => (
                        <li
                          key={i}
                          style={
                            this.state.instituicoesSelecionadas.includes(inst)
                              ? {
                                  backgroundColor: '#007bff',
                                  margin: '3px',
                                  padding: 3,
                                  color: '#fff',
                                }
                              : { margin: '3px', padding: 3 }
                          }
                          onClick={() => this.selectInst(inst)}
                        >
                          {inst.sigla} - {inst.nomeintituicao}
                        </li>
                      ))}
                    </ul>
                    <div style={{ marginTop: 20 }}>
                      <Button
                        outline
                        color='primary'
                        onClick={() => this.limparSelecaoInst()}
                      >
                        Limpar Selecão
                      </Button>{' '}
                      <div>
                        {this.state.instituicoesSelecionadas.length}{' '}
                        Selecionadas
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle>
                      Cursos
                      <Button
                        color='info'
                        outline
                        className='float-right'
                        onClick={(e) =>
                          this.setState({ flipped: !this.state.flipped })
                        }
                      >
                        <i className='fas fa-university'></i>
                      </Button>
                    </CardTitle>
                    <div
                      style={{ marginTop: 20, marginBottom: 10 }}
                      className='input-group'
                    >
                      <input
                        value={this.state.inputBusca}
                        onChange={this.handleBuscaInputCurso.bind(this)}
                        type='text'
                        className='form-control'
                      />
                      <div className='input-group-append'>
                        <button
                          className='btn btn-outline-secondary'
                          type='button'
                        >
                          <i className='fa fa-search'></i>
                        </button>
                      </div>
                    </div>
                    <p style={{ padding: '5px 0px 5px 0px' }}>
                      {this.state.cursosFiltrados.length}&nbsp;cursos
                      encontrados
                    </p>
                    <ul
                      style={{
                        maxHeight: 350,
                        overflow: 'auto',
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                        cursor: 'pointer',
                      }}
                    >
                      {this.state.cursosFiltrados.map((curso, i) => (
                        <li
                          key={i}
                          style={
                            this.state.cursosSelecionados.includes(curso)
                              ? {
                                  backgroundColor: '#007bff',
                                  margin: '3px',
                                  padding: 3,
                                  color: '#fff',
                                }
                              : { margin: '3px', padding: 3 }
                          }
                          onClick={() => this.selectCurso(curso)}
                        >
                          {curso.nomecurso}
                        </li>
                      ))}
                    </ul>
                    <div style={{ marginTop: 20 }}>
                      <Button
                        outline
                        color='primary'
                        onClick={() => this.limparSelecaoCurso()}
                      >
                        Limpar Selecão
                      </Button>{' '}
                      {this.state.cursosSelecionados.length} Selecionadas
                    </div>
                  </CardBody>
                </Card>
              </Flipcard>
            </Col>
            <Col md='6' sm='12' xs='12' id='box-mapa'>
              <div
                className='card'
                style={{
                  textAlign: 'center',
                  background: 'white',
                  padding: 5,
                  boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
                }}
              >
                <h5 style={{ margin: 0 }}>Selecione o estado</h5>
              </div>
              <div>
                <div
                  id='vmap'
                  style={{
                    width: '100%',
                    height: '680px',
                    position: 'block',
                    overflow: 'hidden',
                  }}
                ></div>
              </div>
            </Col>
            <Col
              md='3'
              sm='12'
              xs='12'
              style={{ padding: '0px 10px 0px 10px' }}
              id='box-buscar'
            >
              {this.state.cidades.length > 0 && this.state.region ? (
                <Card
                  style={{
                    marginBottom: 10,
                    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
                  }}
                >
                  <CardBody>
                    <CardTitle
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      Cidades
                      <span style={{ fontSize: 12, fontWeight: 400 }}>
                        {this.state.cidadesSelecionadas.length} selecionadas |{' '}
                        {this.state.cidadesFiltradas.length} encontradas
                      </span>
                    </CardTitle>
                    <div
                      style={{ marginTop: 20, marginBottom: 10 }}
                      className='input-group'
                    >
                      <input
                        value={this.state.inputBuscaCidade}
                        onChange={this.handleBuscaInputCidades.bind(this)}
                        type='text'
                        className='form-control'
                      />
                      <div className='input-group-append'>
                        <button className='btn btn-secondary' type='button'>
                          <i className='fa fa-search'></i>
                        </button>
                      </div>
                    </div>
                    {this.state.carregando ? (
                      <i className='fa fa-spin fa-spinner float-right'></i>
                    ) : null}
                    <ul
                      style={{
                        maxHeight: 300,
                        overflow: 'auto',
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                        cursor: 'pointer',
                        border: '1px solid #ced4da',
                        paddingLeft: 7,
                        borderRadius: '.25rem',
                      }}
                    >
                      {this.state.cidadesFiltradas.map((cidade, i) => (
                        <li
                          key={i}
                          style={
                            this.state.cidadesSelecionadas.includes(cidade)
                              ? {
                                  backgroundColor: '#007bff',
                                  margin: '3px',
                                  padding: 3,
                                  color: '#fff',
                                }
                              : { margin: '3px', padding: 3 }
                          }
                          onClick={() => this.selectCidade(cidade)}
                        >
                          {cidade.cidade}
                          {cidade.vendedores.map((v, i) => (
                            <i
                              key={i}
                              id={`v_${v.id}c_${cidade.codigo}`}
                              className='float-right fas fa-user-tag text-success'
                            ></i>
                          ))}
                          {cidade.vendedores.map((v, i) => (
                            <UncontrolledTooltip
                              key={i}
                              placement='top'
                              target={`v_${v.id}c_${cidade.codigo}`}
                            >
                              {v.nome ? v.nome : null}
                            </UncontrolledTooltip>
                          ))}
                        </li>
                      ))}
                    </ul>
                    <div
                      style={{
                        marginBottom: 10,
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {this.state.cidadesSelecionadas.length > 0 &&
                      this.state.usuarioLogado.Nivel !== 'RESTRITO' ? (
                        <ReactHTMLTableToExcel
                          className='btn btn-link btnInfo'
                          table='tabelaCidades'
                          filename='cidades'
                          sheet='tablexls'
                          buttonText={
                            <span>
                              <i className='fas fa-download'></i> Baixar
                            </span>
                          }
                        />
                      ) : (
                        <span style={{ width: 1, height: 1 }}></span>
                      )}
                      <div>
                        {this.state.cidadesSelecionadas.length > 0 ? (
                          <Button
                            color='link'
                            size='sm'
                            onClick={() => {
                              this.limparSelecao();
                            }}
                            style={{ padding: 0, fontSize: 12 }}
                          >
                            Limpar seleção
                          </Button>
                        ) : null}
                        <Button
                          color='link'
                          size='sm'
                          onClick={() => {
                            this.setState(
                              {
                                cidadesSelecionadas: this.state
                                  .cidadesFiltradas,
                              },
                              () => {
                                this.exportarCidadesSelecionadas();
                              }
                            );
                          }}
                          style={{ padding: 0, fontSize: 12, marginLeft: 10 }}
                        >
                          Selecionar todas
                        </Button>
                      </div>
                    </div>
                    {this.state.usuarioLogado.Nivel !== 'RESTRITO' ? (
                      <Button
                        outline
                        color='primary'
                        className='float-right'
                        onClick={this.toggleModal}
                        disabled={!this.state.cidadesSelecionadas.length > 0}
                      >
                        <i className='fas fa-hand-pointer'></i>
                      </Button>
                    ) : null}
                    <div style={{ display: 'none' }}>
                      <table className='table' id='tabelaCidades'>
                        <thead>
                          <tr>
                            <th>Cidade</th>
                            <th>Vendedores</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.valoresTabelaExportar.length > 0 ? (
                            this.state.valoresTabelaExportar.map((item) => (
                              <tr>
                                <td>{item.cidade}</td>
                                <td>{item.vendedores}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>Nenhuma cidade selecionada</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              ) : null}
              {/* Card da campanha 2019.2 */}
              {/* <Card style={{boxShadow: "0 2px 5px 0 rgba(0,0,0,.26)", marginBottom: 10, backgroundColor: '#333', border: '1px solid #ffffff !important', color: "#fff" }}>
                <CardBody>
                  <CardTitle style={{textAlign: "center", fontWeight: "bolder"}}>Campanha 2019.2</CardTitle>
                  <br/>
                  <section style={{display: "flex", justifyContent: "space-around"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                      <small>Meta:</small>
                      <div style={{fontWeight: "bolder", fontSize: 40, textAlign: "center"}}>90</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                      <small>{this.state.usuarioLogado.Funcao === "ORCAMENTISTA" ? <i style={{cursor: "pointer"}} title="Alterar" className="fas fa-edit" onClick={() => {this.setState({showModalAlterarQtdMetaRealizada: true, qtdMetaRealizada: this.state.metaRealizada})}}></i> : null} Realizada: </small>
                      <div style={{fontWeight: "bolder", fontSize: 40, textAlign: "center"}}>{this.state.metaRealizada}</div>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                      <small>Ainda falta:</small>
                      <div style={{fontWeight: "bolder", fontSize: 40, textAlign: "center"}}>{this.state.metaFaltante}</div>
                    </div>
                  </section>
                </CardBody>
              </Card> */}
              <Card style={{ boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)' }}>
                <CardBody>
                  <CardTitle>Buscar por: </CardTitle>
                  <div style={{ width: '100%' }}>
                    {!this.state.cidadesSelecionadas.length > 0 &&
                    !this.state.instituicoesSelecionadas.length > 0 &&
                    !this.state.cursosSelecionados.length > 0 &&
                    !this.state.code &&
                    !this.state.responsavel
                      ? 'Nenhum filtro selecionado'
                      : null}
                    {this.state.cidadesSelecionadas.length > 0 ? (
                      <div>
                        <strong>Cidades: &nbsp;</strong>
                        {this.state.cidadesSelecionadas.map((c, i) => {
                          let v =
                            i !== this.state.cidadesSelecionadas.length - 1
                              ? ', '
                              : '';
                          return c.cidade + v;
                        })}
                        <br />
                      </div>
                    ) : null}
                    {this.state.instituicoesSelecionadas.length > 0 ? (
                      <div>
                        <strong>Instituições: &nbsp;</strong>
                        {this.state.instituicoesSelecionadas.map((c, i) => {
                          let v =
                            i !== this.state.instituicoesSelecionadas.length - 1
                              ? ', '
                              : '';
                          return c.sigla + v;
                        })}
                        <br />
                      </div>
                    ) : null}
                    {this.state.cursosSelecionados.length > 0 ? (
                      <div>
                        <strong>Cursos: &nbsp;</strong>
                        {this.state.cursosSelecionados.map((c, i) => {
                          let v =
                            i !== this.state.cursosSelecionados.length - 1
                              ? ', '
                              : '';
                          return c.nomecurso + v;
                        })}
                        <br />
                      </div>
                    ) : null}
                    {this.state.responsavel ? (
                      <div>
                        <strong>Representante: </strong>
                        {this.state.responsavel.label}
                        <br />
                      </div>
                    ) : null}
                    {this.state.code ? (
                      <div>
                        <strong>Estado: </strong>
                        {this.state.region}
                        <br />
                      </div>
                    ) : null}
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div>
                      {this.state.usuarioLogado.Funcao !== 'VENDEDOR' ? (
                        <div>
                          <input
                            type='checkbox'
                            ref='cidadesVinculadas'
                            className=''
                          />{' '}
                          Cidades vinculadas <br />
                          <input
                            type='checkbox'
                            ref='cidadesSemVinculo'
                            className=''
                          />{' '}
                          Cidades sem vinculo <br />
                        </div>
                      ) : null}
                      <input
                        type='checkbox'
                        ref='cursosFavoritos'
                        className=''
                      />{' '}
                      Cursos favoritados
                      {this.state.usuarioLogado.Funcao !== 'VENDEDOR' &&
                      this.state.responsavel ? (
                        <ReactHTMLTableToExcel
                          className='btn btn-link btnInfo'
                          table='tabelaCidadesVendedor'
                          filename='cidades'
                          sheet='tablexls'
                          buttonText={
                            <span>
                              <i className='fas fa-download'></i> Baixar relação
                              de cidades vinculadas ao vendedor
                            </span>
                          }
                        />
                      ) : null}
                      <div style={{ display: 'none' }}>
                        <table className='table' id='tabelaCidadesVendedor'>
                          <thead>
                            <tr>
                              <th colSpan={2}>
                                Vendedor:{' '}
                                {this.state.responsavel
                                  ? this.state.responsavel.label
                                  : 'Nenhum vendedor selecionado'}
                              </th>
                            </tr>
                            <tr>
                              <th>Cidades</th>
                              <th>Estados</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.valoresTabelaCidadeVendedor.length >
                            0 ? (
                              this.state.valoresTabelaCidadeVendedor.map(
                                (item, i) => (
                                  <tr key={i}>
                                    <td>{item.nomecidade}</td>
                                    <td>{item.siglaestado}</td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td>Nenhum vendedor selecionado</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div>
                      <br /> Consultar por:
                      <div className='row'>
                        <div className='col-sm-6' style={{ paddingRight: 7.5 }}>
                          <Button
                            disabled={
                              !this.state.cidadesSelecionadas.length > 0 &&
                              !this.state.instituicoesSelecionadas.length > 0 &&
                              !this.state.cursosSelecionados.length > 0 &&
                              !this.state.code &&
                              !this.state.responsavel
                            }
                            outline
                            style={{ marginTop: 8 }}
                            block
                            onClick={() => this.gotoKanban()}
                            color='info'
                          >
                            Kanban <Badge color='warning'>BETA</Badge>
                          </Button>
                        </div>
                        <div className='col-sm-6' style={{ paddingLeft: 7.5 }}>
                          <Button
                            disabled={
                              !this.state.cidadesSelecionadas.length > 0 &&
                              !this.state.instituicoesSelecionadas.length > 0 &&
                              !this.state.cursosSelecionados.length > 0 &&
                              !this.state.code &&
                              !this.state.responsavel
                            }
                            outline
                            style={{ marginTop: 8 }}
                            block
                            onClick={() => this.proximo()}
                            color='success'
                          >
                            Lista
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          isOpen={this.state.modalApontar}
          toggle={this.toggleModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleModal}>
            Apontar responsavel
          </ModalHeader>
          <ModalBody>
            {this.state.cidadesSelecionadas.length > 0 ? (
              <div>
                <strong>Cidades Selecionadas: &nbsp;</strong>
                {this.state.cidadesSelecionadas.map((c, i) => {
                  let v = '';
                  if (i !== this.state.cidadesSelecionadas.length - 1) v = ', ';
                  return c.cidade + v;
                })}
                <br />
              </div>
            ) : null}
            {this.state.instituicoesSelecionadas.length > 0 ? (
              <div>
                <strong>Instituições Selecionadas: &nbsp;</strong>
                {this.state.instituicoesSelecionadas.map((c, i) => {
                  let v = '';
                  if (i !== this.state.instituicoesSelecionadas.length - 1)
                    v = ', ';
                  return c.sigla + v;
                })}
                <br />
              </div>
            ) : (
              <div>
                <strong>Instituições Selecionadas: &nbsp;</strong> Todas
              </div>
            )}
            <FormGroup>
              <Label for='selectVendedor'>
                <strong>Selecione o representante:</strong>
              </Label>
              <select
                ref={(input) => (this.selectV = input)}
                className='form-control'
                name='selectVendedor'
                id='selectVendedor'
              >
                {this.state.vendedores.map((v, i) => (
                  <option key={i} value={v.value}>
                    {v.label}
                  </option>
                ))}
              </select>
            </FormGroup>
            <FormGroup hidden='true'>
              <input type='checkbox' ref='vendedorsecundario' /> Vendedor
              Secundario
            </FormGroup>
            {this.state.mensagem ? (
              <Alert color='success'>{this.state.mensagem}</Alert>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={() => this.apontarVendedor()}>
              Apontar
            </Button>{' '}
            <Button color='secondary' onClick={this.toggleModal}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.showModalAlterarQtdMetaRealizada} size='sm'>
          <form onSubmit={this.salvarMetaRealizada.bind(this)}>
            <ModalHeader> Alterar a quantidade para? </ModalHeader>
            <ModalBody>
              <input
                className='form-control'
                type='number'
                name='metaRealizada'
                min='0'
                required
                value={this.state.qtdMetaRealizada}
                onChange={(e) => {
                  this.setState({ qtdMetaRealizada: e.target.value });
                }}
              />
            </ModalBody>
            <ModalFooter>
              {this.state.carregando ? (
                <i className='fas fa-spinner fa-spin'></i>
              ) : null}
              <Button
                disabled={this.state.carregando}
                type='submit'
                color='success'
              >
                <i className='fas fa-check-circle'></i> Alterar
              </Button>
              <Button
                disabled={this.state.carregando}
                color='danger'
                onClick={() =>
                  this.setState({ showModalAlterarQtdMetaRealizada: false })
                }
              >
                {' '}
                <i className='fas fa-times-circle'></i> Cancelar
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </div>
    );
  }
}

export default Mapa;
