import React from 'react';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from 'reactstrap';

import ModalCursos from '../components/ModalCursos';
import { REQUEST_URL, WEBHOOK_B24 } from '../config/urls.js';

const ESTADOS_BITRIX = [
  { value: 4611, key: 'AC' },
  { value: 4613, key: 'AL' },
  { value: 4615, key: 'AP' },
  { value: 4617, key: 'AM' },
  { value: 4619, key: 'BA' },
  { value: 4621, key: 'CE' },
  { value: 4623, key: 'DF' },
  { value: 4625, key: 'ES' },
  { value: 4627, key: 'GO' },
  { value: 4629, key: 'MA' },
  { value: 4631, key: 'MT' },
  { value: 4633, key: 'MS' },
  { value: 4635, key: 'MG' },
  { value: 4637, key: 'PA' },
  { value: 4639, key: 'PB' },
  { value: 4641, key: 'PR' },
  { value: 4643, key: 'PE' },
  { value: 4645, key: 'PI' },
  { value: 4647, key: 'RJ' },
  { value: 4649, key: 'RN' },
  { value: 4651, key: 'RS' },
  { value: 4653, key: 'RO' },
  { value: 4655, key: 'RR' },
  { value: 4657, key: 'SC' },
  { value: 4659, key: 'SP' },
  { value: 4661, key: 'SE' },
  { value: 4663, key: 'TO' },
];

export default class Cadastro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptionEstado: '',
      selectedOptionCidade: '',
      selectedOptionInst: '',
      selectedOptionCurso: '',
      selectedOptionVendedor: '',
      selectedOptionAgencia: '',
      selectedOptionSource: '',
      selectedOptionAnoFormatura: '',
      selectedOptionSemestre: '',
      selectedOptionTurno: '',

      contatos: [],
      estados: [
        { value: 'AC', label: 'Acre', id: 4611 },
        { value: 'AL', label: 'Alagoas', id: 4613 },
        { value: 'AP', label: 'Amapá', id: 4615 },
        { value: 'AM', label: 'Amazonas', id: 4617 },
        { value: 'BA', label: 'Bahia', id: 4619 },
        { value: 'CE', label: 'Ceará', id: 4621 },
        { value: 'DF', label: 'Distrito Federal', id: 4623 },
        { value: 'ES', label: 'Espírito Santo', id: 4625 },
        { value: 'GO', label: 'Goiás', id: 4627 },
        { value: 'MA', label: 'Maranhão', id: 4629 },
        { value: 'MT', label: 'Mato Grosso', id: 4631 },
        { value: 'MS', label: 'Mato Grosso do Sul', id: 4633 },
        { value: 'MG', label: 'Minas Gerais', id: 4635 },
        { value: 'PA', label: 'Pará', id: 4637 },
        { value: 'PB', label: 'Paraíba', id: 4639 },
        { value: 'PR', label: 'Paraná', id: 4641 },
        { value: 'PE', label: 'Pernambuco', id: 4643 },
        { value: 'PI', label: 'Piauí', id: 4645 },
        { value: 'RJ', label: 'Rio de Janeiro', id: 4647 },
        { value: 'RN', label: 'Rio Grande do Norte', id: 4649 },
        { value: 'RS', label: 'Rio Grande do Sul', id: 4651 },
        { value: 'RO', label: 'Rondônia', id: 4653 },
        { value: 'RR', label: 'Roraima', id: 4655 },
        { value: 'SC', label: 'Santa Catarina', id: 4657 },
        { value: 'SP', label: 'São Paulo', id: 4659 },
        { value: 'SE', label: 'Sergipe', id: 4661 },
        { value: 'TO', label: 'Tocantins', id: 4663 },
      ],
      cidades: [],
      insts: [],
      cursos: [],
      responseCursos: [],
      vendedores: [],
      agencias: [],
      sources: [],
      anos: [],
      semestres: [],
      turnos: [
        { value: 'Matutino', label: 'Matutino' },
        { value: 'Vespertino', label: 'Vespertino' },
        { value: 'Noturno', label: 'Noturno' },
        { value: 'Integral', label: 'Integral' },
      ],
      collapse: false,
      form: {
        nome: '',
        sobrenome: '',
        formatura: '',
        endereco: '',
        nomecontato: '',
        email: '',
        celular: '',
        obs: '',
        qtdFormandos: '',
        CNPJ: '',
      },
      labelsBitrix: {
        estado: '',
        cidade: '',
        instituicao: '',
        curso: '',
      },
      acesso: {},
      usuarioLogado: {},
      cadastrando: false,
      negocio: null,
      preenchendo: true,
      showModalAdicionarCurso: false,
    };
  }
  alterarForm(event) {
    const campo = event.target.name;
    const form = this.state.form;
    form[campo] = event.target.value;
    return this.setState({ form });
  }
  handleChangeEstado(selectedOptionEstado) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.setState({
        selectedOptionEstado,
        selectedOptionCidade: '',
        selectedOptionInst: '',
      });
      if (selectedOptionEstado) {
        axios
          .post(
            REQUEST_URL + '/cidades',
            { estado_cod: selectedOptionEstado.value },
            { headers: { Authorization: sessionStorage.getItem('token') } }
          )
          .then((response) => {
            let cidades = [];
            response.data.map((c) => {
              let cidade = {
                value: c.codigo,
                label: c.cidade,
              };
              cidades.push(cidade);
            });
            resolve(cidades);
            self.setState({ cidades });
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }
  handleChangeCidade(selectedOptionCidade) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.setState({
        selectedOptionCidade,
        selectedOptionInst: '',
        selectedOptionCurso: '',
      });
      if (selectedOptionCidade) {
        axios
          .post(
            REQUEST_URL + '/instituicoes',
            { cidade_cod: selectedOptionCidade.value },
            { headers: { Authorization: sessionStorage.getItem('token') } }
          )
          .then((response) => {
            let insts = [];
            response.data.map((i) => {
              let inst = {
                value: i.cod_ies,
                label: i.sigla + ' - ' + i.nomeintituicao,
                sigla: i.sigla,
              };
              insts.push(inst);
            });
            self.setState({ insts });
            resolve(insts);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }
  handleChangeInst(selectedOptionInst) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.setState({ selectedOptionInst, selectedOptionCurso: '' });
      if (selectedOptionInst) {
        axios
          .post(
            REQUEST_URL + '/cursos',
            {
              cidade_cod: this.state.selectedOptionCidade.value,
              inst_cod: selectedOptionInst.value,
            },
            { headers: { Authorization: sessionStorage.getItem('token') } }
          )
          .then((response) => {
            let cursos = [];
            response.data.map((c) => {
              if (c.esconder !== 1) {
                cursos.push({
                  value: c.idcurso,
                  label: c.nomecurso,
                  id_curso_inst: c.id_curso_inst,
                });
              }
            });
            self.setState({ cursos, responseCursos: response.data });
            resolve(cursos);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }
  handleChangeCurso(selectedOptionCurso) {
    this.setState({ selectedOptionCurso });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedOptionCurso) {
      // console.log(`Selected: ${selectedOptionCurso.label}`);
    }
  }
  handleChangeVendedor(selectedOptionVendedor) {
    this.setState({ selectedOptionVendedor });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedOptionVendedor) {
      // console.log(`Selected: ${selectedOptionVendedor.label} , idBitrix: ${selectedOptionVendedor.idBitrix}`);
    }
  }
  handleChangeAgencia(selectedOptionAgencia) {
    this.setState({ selectedOptionAgencia });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedOptionAgencia) {
      // console.log(`Selected: ${selectedOptionAgencia.label}`);
    }
  }
  handleChangeSource(selectedOptionSource) {
    this.setState({ selectedOptionSource });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedOptionSource) {
      // console.log(`Selected: ${selectedOptionSource.label}`);
    }
  }
  handleChangeAnoFormatura(selectedOptionAnoFormatura) {
    this.setState({ selectedOptionAnoFormatura });
    if (selectedOptionAnoFormatura) {
      // console.log(`Selected: ${selectedOptionAnoFormatura.label}`);
    }
  }
  handleChangeSemestre(selectedOptionSemestre) {
    this.setState({ selectedOptionSemestre });
    if (selectedOptionSemestre) {
      // console.log(`Selected: ${selectedOptionSemestre.label}`);
    }
  }
  handleChangeTurno(selectedOptionTurno) {
    this.setState({ selectedOptionTurno });
    if (selectedOptionTurno) {
      // console.log(`Selected: ${selectedOptionTurno.label}`);
    }
  }
  removerContato(contato) {
    // console.log(contato);
    this.setState({
      contatos: this.state.contatos.filter((c) => {
        return c !== contato;
      }),
    });
  }
  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  salvar = async (e) => {
    e.preventDefault();
    //this.addLead();
    let form = this.state.form;
    let cliente = {
      nome: form.nome,
      sobrenome: form.sobrenome === null ? '' : form.sobrenome,
      // codEstado:this.state.selectedOptionEstado.value,
      // codCidade:this.state.selectedOptionCidade.value,
      // codInst:this.state.selectedOptionInst.value,
      // codCurso:this.state.selectedOptionCurso.value,

      // cidade:this.state.selectedOptionCidade.label,
      // instituicao:this.state.selectedOptionInst.label.split(" - ")[1],
      // sigla:this.state.selectedOptionInst.label.split(" - ")[0],
      // curso:this.state.selectedOptionCurso.label,

      codAgencia: this.state.selectedOptionAgencia
        ? this.state.selectedOptionAgencia.value
        : 0,
      vendedor: this.state.selectedOptionVendedor.value,

      formatura:
        this.state.selectedOptionAnoFormatura.label +
        '/' +
        (this.state.selectedOptionSemestre.label === 'Primeiro Semestre'
          ? '1'
          : '2'),
      entrega: form.endereco,
      contatos: this.state.contatos,
      status: 'Prospecto',
      turno: this.state.selectedOptionTurno.label,
      idCursoInst: this.state.selectedOptionCurso.id_curso_inst,
    };

    if (cliente.contatos.length === 0) {
      toast.warn('Insira pelo menos um contato para este cliente', {
        position: 'bottom-left',
        autoClose: false,
        closeOnClick: true,
      });
    } else if (!cliente.vendedor) {
      toast.warn('Vendedor não informado', {
        position: 'bottom-left',
        autoClose: false,
        closeOnClick: true,
      });
    } else {
      await axios
        .post(REQUEST_URL + '/encontrar-cliente', cliente, {
          headers: { Authorization: sessionStorage.getItem('token') },
        })
        .then(async (data) => {
          // Se o cliente não existe, ele será cadastrado no bitrix e também no mapa
          // console.log(data);
          if (!data.data) {
            //cadastra no bitrix
            this.setState({ cadastrando: true });

            let bitrixResponse;
            //Se tiver negocio carregado editar
            if (this.state.negocio && this.state.negocio.ID) {
              bitrixResponse = await this.editDeal();
              cliente.idNegocioBitrix = this.state.negocio.ID;
            } else {
              bitrixResponse = await this.addDeal();
              cliente.idNegocioBitrix = bitrixResponse.result;
            }

            axios
              .post(REQUEST_URL + '/cadastrar_cliente', cliente, {
                headers: { Authorization: sessionStorage.getItem('token') },
              })
              .then((response) => {
                this.setState({
                  form: {
                    nome: '',
                    sobrenome: '',
                    formatura: '',
                    endereco: '',
                    obs: '',
                    qtdFormandos: '',
                  },
                  contatos: [],
                  cadastrando: false,
                  selectedOptionEstado: '',
                  selectedOptionCidade: '',
                  selectedOptionInst: '',
                  selectedOptionCurso: '',
                  selectedOptionVendedor: '',
                  selectedOptionAgencia: '',
                  selectedOptionTurno: '',
                  selectedOptionAnoFormatura: '',
                  selectedOptionSemestre: '',
                  selectedOptionSource: '',
                });
                if (response.data.id) {
                  toast.success('Cliente cadastrado com sucesso!', {
                    position: 'bottom-left',
                    hideProgressBar: true,
                    closeOnClick: true,
                  });
                }
              })
              .catch((err) => {
                toast.error('Não foi posivel cadastrar esse cliente');
                this.setState({ cadastrando: false });
              });
          } else {
            toast.error('Este cliente já está cadastrado!');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao cadastrar o lead');
        });
    }
  };
  componentDidMount() {
    delete axios.defaults.headers.common['Authorization'];

    if (sessionStorage.getItem('usuarioLogado')) {
      this.setState(
        {
          usuarioLogado: JSON.parse(sessionStorage.getItem('usuarioLogado'))[0],
        },
        () => {
          if (this.state.usuarioLogado.Funcao === 'VENDEDOR') {
            this.setState({
              selectedOptionVendedor: {
                value: this.state.usuarioLogado.idUsuario,
                label: this.state.usuarioLogado.Nome,
                idBitrix: this.state.usuarioLogado.idBitrix,
              },
            });
          }
        }
      );
    }
    axios
      .post(`${WEBHOOK_B24}/crm.deal.fields`, {
        filter: { TITLE: 'TESTE' },
        select: ['UF_*', '*'],
      })
      .then((response) => {
        // console.log("conectado com bitrix");
        this.tratarCamposNegocio(response.data.result);
        //ids vindos da tela faculdades para preecher automativo
        if (this.props.location.state) {
          if (this.props.location.state.ids) {
            this.preencherNegocio();
          } else if (this.props.location.state.negocio) {
            // Quando o cliente ja ta cadastrado e esta reabrindo
            this.carregarNegocio();
          }
        } else {
          this.setState({ preenchendo: false });
        }
      });
    this.buscarVendedores();
    this.buscarAgencias();
  }
  handleCloseModalCurso(resposta) {
    if (resposta.tipo === 'sucesso') {
      toast.success(resposta.mensagem);
    }
    this.setState({ showModalAdicionarCurso: false });
    this.handleChangeInst(this.state.selectedOptionInst);
  }
  preencherNegocio() {
    this.setState({ preenchendo: true });
    let ids = this.props.location.state.ids;
    let selectEstado = this.state.estados.filter(
      (estado) => estado.value === ids.siglaestado
    )[0];
    this.handleChangeEstado(selectEstado).then(() => {
      let selectCidade = this.state.cidades.filter(
        (cidade) => cidade.value === ids.cod_cidade
      )[0];
      this.handleChangeCidade(selectCidade).then(() => {
        let selectInsts = this.state.insts.filter(
          (instituicao) => instituicao.value === ids.cod_inst
        )[0];
        this.handleChangeInst(selectInsts).then(() => {
          let selectCurso = this.state.cursos.filter(
            (curso) => curso.value === ids.cod_curso
          )[0];
          this.handleChangeCurso(selectCurso);
          try {
            let p_ano = ids.semestre.substring(0, 4);
            let p_semestre = ids.semestre.substring(3, 4);
            p_semestre = p_semestre ? 'Primeiro Semestre' : 'Segundo Semestre';
            // console.log(p_ano,p_semestre);

            let ano = this.state.anos.filter((ano) => {
              return ano.label === p_ano;
            })[0];
            // console.log(ano);
            this.setState({ selectedOptionAnoFormatura: ano });

            let semestre = this.state.semestres.filter((semestre) => {
              return semestre.label === p_semestre;
            })[0];
            // console.log(semestre);
            this.setState({ selectedOptionSemestre: semestre });

            this.setState({ preenchendo: false });
          } catch (e) {
            toast('Não foi possivel carregar os dados do mapa');
          }
        });
      });
    });
  }
  carregarNegocio() {
    this.setState({ negocio: this.props.location.state.negocio }, () => {
      if (this.state.negocio) {
        let negocio = this.state.negocio;
        this.setState({
          form: {
            nome: negocio.TITLE,
            sobrenome: negocio.UF_CRM_LAST_NAME,
            endereco: negocio.UF_CRM_ADDRESS,
            nomecontato: '',
            email: '',
            celular: '',
            telefone: '',
            rg: '',
            cpf: '',
            obs: negocio.UF_CRM_5A4F7978EE330,
            qtdFormandos: negocio.UF_CRM_67A4BE66A93AB,
          },
        });
        let source = this.state.sources.filter((cidade) => {
            return cidade.value === this.state.negocio.UF_CRM_SOURCE_ID;
          }),
          ano = this.state.anos.filter((ano) => {
            return ano.value === negocio.UF_CRM_67A4BE669A07A;
          }),
          semestre = this.state.semestres.filter((semestre) => {
            return semestre.value === negocio.UF_CRM_67A4BE668A700;
          }),
          vendedor = this.state.vendedores.filter((v) => {
            return v.idBitrix === Number(negocio.ASSIGNED_BY_ID);
          }),
          agencia = this.state.agencias.filter((a) => {
            return a.label === negocio.UF_CRM_1533575132;
          }),
          contato = {
            nome: negocio.TITLE,
            celular: negocio.UF_CRM_1516709982,
            email: negocio.UF_CRM_EMAIL_WORK,
            principal: true,
          },
          estado = this.state.estados.filter((e) => {
            return e.id === Number(negocio.UF_CRM_1563819157);
          }),
          contatos = this.state.contatos;
        contatos.push(contato);
        this.setState({
          selectedOptionSemestre: semestre[0],
          selectedOptionVendedor: vendedor[0],
          selectedOptionAgencia: agencia[0],
          selectedOptionAnoFormatura: ano[0],
          selectedOptionSource: source[0],
          contatos,
          preenchendo: false,
          labelsBitrix: {
            estado: `Bitrix: ${
              estado[0] === undefined || estado[0] === null
                ? 'Não definido'
                : estado[0].label
            }`,
            cidade: `Bitrix: ${
              negocio.UF_CRM_1558961165 === undefined ||
              negocio.UF_CRM_1558961165 === null
                ? 'Não definido'
                : negocio.UF_CRM_1558961165
            }`,
            instituicao: `Bitrix: ${
              negocio.UF_CRM_664F907E943ED === undefined ||
              negocio.UF_CRM_664F907E943ED === null
                ? 'Não definido'
                : negocio.UF_CRM_664F907E943ED
            }`,
            curso: `Bitrix: ${
              negocio.UF_CRM_664F907E70538 === undefined ||
              negocio.UF_CRM_664F907E70538 === null
                ? 'Não definido'
                : negocio.UF_CRM_664F907E70538
            }`,
          },
        });
      }
    });
  }
  addDeal() {
    delete axios.defaults.headers.common['Authorization'];
    let self = this;
    return new Promise(function(resolve, reject) {
      let lead = {
        auth: self.state.acesso.access_token,
        fields: {
          TITLE: `${self.state.form.nome} ${self.state.form.sobrenome}`,
          STAGE_ID: 'C15:PREPARATION',
          CATEGORY_ID: '15',
          UF_CRM_NAME: `${self.state.form.nome} ${self.state.form.sobrenome}`,
          UF_CRM_LAST_NAME: self.state.form.sobrenome,

          UF_CRM_ADDRESS: self.state.form.endereco, //muda
          UF_CRM_ADDRESS_CITY: self.state.selectedOptionCidade.label, //muda
          UF_CRM_1558961165: self.state.selectedOptionCidade.label, //Cidade
          UF_CRM_1563819157: ESTADOS_BITRIX.filter((e) => {
            return e.key === self.state.selectedOptionEstado.value;
          })[0].value, //Estado
          //"ADDRESS_PROVINCE":this.state.selectedOptionEstado.value,//muda
          OPENED: 'N',
          ASSIGNED_BY_ID: self.state.selectedOptionVendedor.idBitrix,
          CURRENCY_ID: 'BRL',
          UF_CRM_PHONE_MOBILE: self.state.contatos[0].celular,
          UF_CRM_1516709982: self.state.contatos[0].celular,
          UF_CRM_PHONE_WORK: self.state.contatos[0].telefone,
          UF_CRM_EMAIL_WORK: self.state.contatos[0].email,
          UF_CRM_1516709968: self.state.contatos[0].email,
          UF_CRM_5B4F6F99E2A9E:
            self.state.usuarioLogado.Nome === 'Marketing Alpha'
              ? '1667'
              : '1669', //Canal de entrada
          UF_CRM_1515246152: '2011', //Tipo do lead
          UF_CRM_SOURCE_ID: self.state.selectedOptionSource.value, //Origem
          UF_CRM_5A4F7978EE330: self.state.form.obs, //Observação
          UF_CRM_67A4BE663F1BA: self.state.selectedOptionCurso.label, //Curso
          UF_CRM_67A4BE664CFEA: self.state.selectedOptionInst.label, //Faculdade
          UF_CRM_67A4BE668A700: self.state.selectedOptionSemestre.value, //Semestre
          UF_CRM_67A4BE669A07A: self.state.selectedOptionAnoFormatura.value, //Ano
          UF_CRM_5A4F79797B484: self.state.selectedOptionInst.sigla, //sigla
          UF_CRM_67A4BE66A93AB: self.state.form.qtdFormandos,
          UF_CRM_1533575132: self.state.selectedOptionAgencia
            ? self.state.selectedOptionAgencia.label
            : '', //Parceiro
        },
      };
      // console.log(lead);
      axios({
        method: 'post',
        url: `${WEBHOOK_B24}/crm.deal.add`,
        data: lead,
      })
        .then(function(response) {
          //console.log(response);
          resolve(response.data);
          toast.success('Negocio bitrix24 criado com sucesso!', {
            position: 'bottom-left',
            hideProgressBar: true,
            closeOnClick: true,
          });
        })
        .catch(function(response) {
          reject();
          toast.error('Não foi possivel cadastrar o Lead no Bitrix24', {
            position: 'bottom-left',
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    });
  }
  editDeal() {
    delete axios.defaults.headers.common['Authorization'];
    let self = this;
    return new Promise(function(resolve, reject) {
      let lead = {
        auth: self.state.acesso.access_token,
        id: self.state.negocio.ID,
        fields: {
          TITLE: `${self.state.form.nome} ${self.state.form.sobrenome}`,
          STAGE_ID: self.state.negocio.STAGE_ID,
          CATEGORY_ID: '15',
          UF_CRM_NAME: `${self.state.form.nome} ${self.state.form.sobrenome}`,
          UF_CRM_LAST_NAME: self.state.form.sobrenome,

          UF_CRM_ADDRESS: self.state.form.endereco, //muda
          UF_CRM_ADDRESS_CITY: self.state.selectedOptionCidade.label, //muda
          //"ADDRESS_PROVINCE":this.state.selectedOptionEstado.value,//muda
          OPENED: 'N',
          ASSIGNED_BY_ID: self.state.selectedOptionVendedor.idBitrix,
          CURRENCY_ID: 'BRL',
          UF_CRM_PHONE_MOBILE: self.state.contatos[0].celular,
          UF_CRM_1516709982: self.state.contatos[0].celular,
          UF_CRM_PHONE_WORK: self.state.contatos[0].telefone,
          UF_CRM_EMAIL_WORK: self.state.contatos[0].email,
          UF_CRM_1516709968: self.state.contatos[0].email,
          UF_CRM_1515246152: '2011', //Tipo do lead
          UF_CRM_SOURCE_ID: self.state.selectedOptionSource.value, //Origem
          UF_CRM_5A4F7978EE330: self.state.form.obs, //Observação
          UF_CRM_67A4BE663F1BA: self.state.selectedOptionCurso.label, //Curso
          UF_CRM_67A4BE664CFEA: self.state.selectedOptionInst.label, //Faculdade
          UF_CRM_67A4BE668A700: self.state.selectedOptionSemestre.value, //Semestre
          UF_CRM_67A4BE669A07A: self.state.selectedOptionAnoFormatura.value, //Ano
          UF_CRM_5A4F79797B484: self.state.selectedOptionInst.sigla, //sigla
          UF_CRM_67A4BE66A93AB: self.state.form.qtdFormandos,
          UF_CRM_1533575132: self.state.selectedOptionAgencia
            ? self.state.selectedOptionAgencia.label
            : '', //Parceiro
        },
      };
      // console.log(lead);
      axios({
        method: 'post',
        url: `${WEBHOOK_B24}/crm.deal.update`,
        data: lead,
      })
        .then(function(response) {
          //console.log(response);
          resolve(response.data);
          toast.success('Negocio bitrix24 atualizado com sucesso!', {
            position: 'bottom-left',
            hideProgressBar: true,
            closeOnClick: true,
          });
        })
        .catch(function(response) {
          reject();
          toast.error('Não foi possivel atualizar o negocio no Bitrix24', {
            position: 'bottom-left',
            hideProgressBar: true,
            closeOnClick: true,
          });
        });
    });
  }
  limpar() {
    this.setState({
      form: {
        nome: '',
        sobrenome: '',
        formatura: '',
        endereco: '',
        obs: '',
        qtdFormandos: '',
      },
      contatos: [],
      selectedOptionEstado: '',
      selectedOptionCidade: '',
      selectedOptionInst: '',
      selectedOptionCurso: '',
      selectedOptionVendedor: '',
      selectedOptionAgencia: '',
      selectedOptionAnoFormatura: '',
      selectedOptionSemestre: '',
      selectedOptionSource: '',
    });
  }
  addContato() {
    let contato = {
      nome: this.state.form.nomecontato,
      celular: this.state.form.celular,
      email: this.state.form.email,
      principal: false,
    };
    if (contato.nome && contato.celular) {
      let contatos = this.state.contatos;
      contatos.push(contato);
      this.setState({ contatos });
      let form = this.state.form;
      form.nomecontato = '';
      form.celular = '';
      form.email = '';
      this.setState({ form, collapse: false });
    }
  }
  buscarVendedores() {
    axios
      .get(REQUEST_URL + '/vendedores', {
        headers: { Authorization: sessionStorage.getItem('token') },
      })
      .then((response) => {
        // console.log(response.data);
        //self.setState({cidades:response.data,cidadesSelecionadas:[]});
        let vendedores = [];
        response.data.map((v) => {
          let vendedor = {
            value: v.idUsuario,
            label: v.Nome,
            idBitrix: v.idBitrix,
          };
          vendedores.push(vendedor);
        });
        this.setState({ vendedores });
      });
  }
  buscarAgencias() {
    axios
      .get(REQUEST_URL + '/agencias', {
        headers: { Authorization: sessionStorage.getItem('token') },
      })
      .then((response) => {
        // console.log(response.data);
        //self.setState({cidades:response.data,cidadesSelecionadas:[]});
        let agencias = [];
        response.data.map((a) => {
          let agencia = {
            value: a.IdAgencia,
            label: a.NomeAgencia,
          };
          agencias.push(agencia);
        });
        this.setState({ agencias });
      });
  }
  tratarCamposNegocio(fields) {
    // console.log( fields );
    let sourceList = fields.UF_CRM_SOURCE_ID.items;
    let anoList = fields.UF_CRM_67A4BE669A07A.items;
    let semestreList = fields.UF_CRM_67A4BE668A700.items;

    let sourceOptions = this.bitrixItemsToSelectOption(sourceList);
    let anoOptions = this.bitrixItemsToSelectOption(anoList);
    let semestreOption = this.bitrixItemsToSelectOption(semestreList);
    this.setState({
      sources: sourceOptions,
      anos: anoOptions,
      semestres: semestreOption,
    });

    // console.log( semestreList);
  }
  bitrixItemsToSelectOption(items) {
    let options = [];
    items.map((i) => {
      let option = {
        value: i.ID,
        label: i.VALUE,
      };
      options.push(option);
    });
    return options;
  }
  render() {
    if (!sessionStorage.getItem('usuarioLogado')) {
      return <Redirect to='/login' />;
    }
    return (
      <div style={{ minHeight: '920px' }}>
        <ToastContainer
          position='bottom-left'
          autoClose={10000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
        />
        <div style={{ maxWidth: 750, margin: 'auto' }}>
          <Card
            style={{
              borderRadius: 0,
              border: 0,
              padding: 20,
              marginTop: 30,
              marginBottom: 20,
              boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
              background: 'white',
              color: 'lightslategray',
            }}
            id='container-cadastro'
          >
            <CardBody>
              <img
                hidden={!this.state.preenchendo}
                src='svgs/loading.svg'
                style={{
                  display: 'block',
                  height: 40,
                  position: 'absolute',
                  top: 50,
                  right: 20,
                  zIndex: 9999,
                }}
              ></img>
              <div>
                {' '}
                <i
                  onClick={() => {
                    window.location.href = '/mapa';
                  }}
                  style={{
                    cursor: 'pointer',
                    float: 'right',
                    position: 'absolute',
                    right: 20,
                    top: 20,
                  }}
                  className='fas fa-times fa-lg'
                ></i>
              </div>
              {this.state.negocio ? (
                <h3>
                  Editar cliente {this.state.negocio.ID} e passar para tabela
                </h3>
              ) : (
                <h3>Cadastro de Cliente</h3>
              )}
              <CardSubtitle>
                Insira as informações do cliente abaixo:
              </CardSubtitle>
            </CardBody>
            <CardBody>
              <Form
                className='smallRed'
                onChange={this.alterarForm.bind(this)}
                onSubmit={this.salvar.bind(this)}
              >
                <Row>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='prospecto'>* Nome</Label>
                      <Input
                        required
                        name='nome'
                        value={this.state.form.nome}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='8'>
                    <FormGroup>
                      <Label for='prospecto'>Sobrenome:</Label>
                      <Input
                        name='sobrenome'
                        value={this.state.form.sobrenome}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='exampleEmail'>* Estado</Label>
                      <Select
                        required
                        placeholder='Selecione o estado'
                        name='form-field-name'
                        value={this.state.selectedOptionEstado}
                        onChange={this.handleChangeEstado.bind(this)}
                        options={this.state.estados}
                      />
                      <small>{this.state.labelsBitrix.estado}</small>
                    </FormGroup>
                  </Col>
                  <Col md='8'>
                    <FormGroup>
                      <Label for='examplePassword'>* Cidade</Label>
                      <Select
                        placeholder='Selecione um cidade'
                        required
                        disabled={!this.state.selectedOptionEstado}
                        name='form-field-name'
                        noOptionsMessage='Nenhuma cidade encontrada'
                        value={this.state.selectedOptionCidade}
                        onChange={this.handleChangeCidade.bind(this)}
                        options={this.state.cidades}
                      />
                      <small>{this.state.labelsBitrix.cidade}</small>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='exampleEmail'>* Instituição</Label>
                      <Select
                        required
                        placeholder='Selecione uma instituição'
                        disabled={
                          !this.state.selectedOptionEstado ||
                          !this.state.selectedOptionCidade
                        }
                        noOptionsMessage={'Nenhuma instituição encontrada'}
                        name='form-field-name'
                        value={this.state.selectedOptionInst}
                        onChange={this.handleChangeInst.bind(this)}
                        options={this.state.insts}
                      />
                      <small>{this.state.labelsBitrix.instituicao}</small>
                    </FormGroup>
                  </Col>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='exampleEmail'>* Curso</Label>
                      <Select
                        required
                        placeholder='Selecione o curso'
                        disabled={
                          !this.state.selectedOptionEstado ||
                          !this.state.selectedOptionCidade ||
                          !this.state.selectedOptionInst
                        }
                        name='form-field-name'
                        noOptionsMessage='Nenhum curso encontrado'
                        value={this.state.selectedOptionCurso}
                        onChange={this.handleChangeCurso.bind(this)}
                        options={this.state.cursos}
                      />
                      <small>{this.state.labelsBitrix.curso}</small>
                      {this.state.selectedOptionEstado &&
                      this.state.selectedOptionCidade &&
                      this.state.selectedOptionInst &&
                      this.state.usuarioLogado.Funcao === 'ORCAMENTISTA' ? (
                        <p
                          className='pLink'
                          onClick={() =>
                            this.setState({ showModalAdicionarCurso: true })
                          }
                        >
                          Não achou o curso? Clique aqui e cadastre.
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='exampleEmail'>* Vendedor</Label>
                      <Select
                        required
                        placeholder='Selecione os vendedores'
                        name='form-field-name'
                        value={this.state.selectedOptionVendedor}
                        onChange={this.handleChangeVendedor.bind(this)}
                        disabled={
                          this.state.usuarioLogado.Funcao === 'VENDEDOR' &&
                          this.state.selectedOptionVendedor
                        }
                        options={
                          this.state.usuarioLogado.Funcao === 'VENDEDOR'
                            ? [
                                {
                                  value: this.state.usuarioLogado.idUsuario,
                                  label: this.state.usuarioLogado.Nome,
                                  idBitrix: this.state.usuarioLogado.idBitrix,
                                },
                              ]
                            : this.state.vendedores
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='examplePassword'>Parceiro</Label>
                      <Select
                        placeholder='Selecione o parceiro'
                        name='form-field-name'
                        value={this.state.selectedOptionAgencia}
                        onChange={this.handleChangeAgencia.bind(this)}
                        options={this.state.agencias}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm='12'>
                    <FormGroup>
                      <Label for='examplePassword'>
                        Quantidade de formandos:{' '}
                      </Label>
                      <Input
                        type='number'
                        name='qtdFormandos'
                        value={this.state.form.qtdFormandos}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='3'>
                    <FormGroup>
                      <Label for='examplePassword'>* Formatura</Label>
                      <Select
                        required
                        loading
                        placeholder='Selecione'
                        name='form-field-name'
                        value={this.state.selectedOptionAnoFormatura}
                        onChange={this.handleChangeAnoFormatura.bind(this)}
                        options={this.state.anos}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='3'>
                    <FormGroup>
                      <Label for='examplePassword'>* Semestre</Label>
                      <Select
                        required
                        placeholder='Selecione'
                        name='form-field-name'
                        value={this.state.selectedOptionSemestre}
                        onChange={this.handleChangeSemestre.bind(this)}
                        options={this.state.semestres}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='3'>
                    <FormGroup>
                      <Label for='examplePassword'>* Turno</Label>
                      <Select
                        required
                        placeholder='Selecione'
                        name='form-field-name'
                        value={this.state.selectedOptionTurno}
                        onChange={this.handleChangeTurno.bind(this)}
                        options={this.state.turnos}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for='examplePassword'>Observações</Label>
                  <Input name='obs' value={this.state.form.obs} />
                </FormGroup>
                <FormGroup>
                  <Label for='examplePassword'>Endereço de entrega</Label>
                  <Input name='endereco' value={this.state.form.endereco} />
                </FormGroup>
                <FormGroup>
                  <Label for='exampleEmail'>* Origem do negócio</Label>
                  <Select
                    required
                    placeholder='Selecione'
                    name='form-field-name'
                    value={this.state.selectedOptionSource}
                    onChange={this.handleChangeSource.bind(this)}
                    options={this.state.sources}
                    disabled={
                      this.props.location.state !== undefined
                        ? this.props.location.state.editar
                        : false
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for='examplePassword'>Contatos: </Label>
                  <Row>
                    {this.state.contatos.map((contato, i) => (
                      <Col key={i} md='6'>
                        <Card
                          body
                          outline
                          style={{
                            border: '1px solid #ced4da',
                            padding: 10,
                            marginBottom: 5,
                            background: '#white',
                          }}
                        >
                          <Button
                            type='button'
                            onClick={this.removerContato.bind(this, contato)}
                            style={{
                              border: 'none',
                              backgroundColor: '#ffffff00',
                              position: 'absolute',
                              top: 2,
                              right: 2,
                              width: 25,
                              height: 25,
                              padding: 0,
                            }}
                          >
                            <i
                              className='fas fa-times '
                              style={{ color: 'gray' }}
                            ></i>
                          </Button>
                          <CardTitle style={{ marginBottom: 0 }}>
                            {contato.nome}
                          </CardTitle>
                          <CardText style={{ fontSize: 12 }}>
                            {contato.email}
                            <br />
                            {contato.celular
                              ? `Celular: ${contato.celular}`
                              : null}
                          </CardText>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                  <Button
                    outline
                    onClick={this.toggle.bind(this)}
                    size='sm'
                    style={{
                      boxShadow:
                        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
                    }}
                  >
                    {!this.state.collapse ? (
                      <div>
                        <i className='fas fa-plus-circle'></i> Novo Contato
                      </div>
                    ) : (
                      <div>
                        <i className='fas fa-minus-circle'></i> Fechar
                      </div>
                    )}
                  </Button>
                  <Collapse isOpen={this.state.collapse}>
                    <Card
                      style={{
                        marginTop: 5,
                        boxShadow:
                          '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for='exampleEmail'>* Nome contato</Label>
                              <Input
                                name='nomecontato'
                                value={this.state.form.nomecontato}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for='exampleEmail'>* E-mail</Label>
                              <Input
                                type='email'
                                className='form-control'
                                name='email'
                                value={this.state.form.email}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for='exampleEmail'>* Celular</Label>
                              <InputMask
                                className='form-control'
                                mask='(99) 9 9999-9999'
                                name='celular'
                                value={this.state.form.celular}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Button
                            outline
                            className='float-right'
                            type='button'
                            color='success'
                            style={{
                              boxShadow:
                                '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
                            }}
                            onClick={this.addContato.bind(this)}
                          >
                            Adicionar <i className='fas fa-arrow-up'></i>
                          </Button>
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Collapse>
                </FormGroup>
                <FormGroup style={{ marginTop: 50 }}>
                  <Button
                    onClick={this.limpar.bind(this)}
                    type='button'
                    style={{
                      boxShadow:
                        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
                    }}
                    outline
                    color='success'
                    size='lg'
                  >
                    <i className='fas fa-trash'></i> Limpar
                  </Button>
                  <Button
                    style={{
                      boxShadow:
                        '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
                    }}
                    outline
                    type='submit'
                    color='success'
                    size='lg'
                    className='float-right'
                  >
                    {!this.state.cadastrando ? (
                      <div>
                        Cadastrar&nbsp;
                        <i className='far fa-arrow-alt-circle-right'></i>
                      </div>
                    ) : (
                      <div>
                        Cadastrando...&nbsp;
                        <i className='fa fa-cog fa-spin fa-fw'></i>
                      </div>
                    )}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </div>
        <Modal
          className='no-print'
          isOpen={this.state.showModalAdicionarCurso}
          size='lg'
        >
          <ModalCursos
            instituicao={{
              ...this.state.selectedOptionInst,
              idcidade: this.state.selectedOptionCidade.value,
            }}
            cursos={this.state.responseCursos}
            close={(resposta) => this.handleCloseModalCurso(resposta)}
          />
        </Modal>
      </div>
    );
  }
}
