import React from 'react';

import FloatingMenuItem from './FloatingMenuItem';
import './index.css';

export default class FloatingMenuOrcamento extends React.Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
    };
  }

  toggleMenu() {
    this.setState({ toggled: !this.state.toggled });
  }

  render() {
    let {
        statusOrcamento,
        ehOrcamentista,
        botoesAcao,
        baixarOrcamento,
        duplicarOrcamento,
        gerarProposta,
        abrirKits,
        salvarOrcamento,
      } = this.props,
      buttons = [],
      className = 'floating-menu';

    if (this.state.toggled) {
      className += ' open';

      buttons.push(
        <FloatingMenuItem
          key='1'
          toggleMenu={this.toggleMenu.bind(this)}
          label='Salvar orçamento'
          icon='fas fa-save'
          disabled={
            statusOrcamento !== 'Negociacao' && statusOrcamento !== 'Aguardando'
          }
          action={salvarOrcamento}
        />
      );
      buttons.push(
        <FloatingMenuItem
          key='2'
          toggleMenu={this.toggleMenu.bind(this)}
          label='Visualizar proposta'
          icon='fas fa-file-pdf'
          disabled={statusOrcamento === 'Fechado'}
          action={gerarProposta}
        />
      );
      buttons.push(
        <FloatingMenuItem
          key='3'
          toggleMenu={this.toggleMenu.bind(this)}
          label='Kits'
          icon='fas fa-boxes'
          disabled={statusOrcamento === 'Fechado'}
          action={abrirKits}
        />
      );

      if (ehOrcamentista) {
        buttons.push(
          <FloatingMenuItem
            key='4'
            toggleMenu={this.toggleMenu.bind(this)}
            label='Baixar orçamento'
            icon='fas fa-download'
            action={baixarOrcamento}
          />
        );
        buttons.push(
          <FloatingMenuItem
            key='5'
            toggleMenu={this.toggleMenu.bind(this)}
            label='Duplicar orçamento'
            icon='fas fa-copy'
            disabled={!botoesAcao}
            action={duplicarOrcamento}
          />
        );
      }
    }

    buttons.push(
      <FloatingMenuItem
        key='0'
        toggleMenu={this.toggleMenu.bind(this)}
        label=''
        icon='fa fa-plus'
        action={this.toggleMenu.bind(this)}
      />
    );

    return (
      <div>
        <div className={className}>{buttons}</div>
      </div>
    );
  }
}
